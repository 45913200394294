import "./section.scss"

function Section(props) {
    return (
        <section id={props.id} className=" margin-top-200">
            {props.children}
        </section>
    );
}

export default Section;
