import React from 'react'
import "./prenota.scss"
import { Formik } from 'formik';
import FormPrenotaTavolo from '../../Components/Forms/FormPrenotaTavolo';
import ita from './ita.json'
import eng from './eng.json'
import { Box, Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import imageBar from '../../assets/bar/_86A8204.jpeg'
const GreenButton = styled(Button)`
    background-color: #5cb77a;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    color: white;
    border: none;
    cursor: pointer;
    &:hover {
      color:#5cb77a;
      background-color: white;
      border: 1px solid #5cb77a;
    }
`;

function Prenota(props) {
  let language = props.isEng ? eng : ita
  return (
    <div id="Prenota" >
      <div className="hero-banner">
      </div>
      <section id="form-prenota">
        <Grid container spacing={2} >
          <Grid item md={6} xs={12} className="hero-heading-box">
            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <h1>{language.h1}</h1>
              <p>Coinbar è un bar innovativo situato nel centro di Roma, che accetta pagamenti in criptovalute come Bitcoin ed Ethereum.<br/> Offre una vasta selezione di cibo e bevande fatte a mano, in un'atmosfera moderna e tecnologica. 
              <br/>Grazie all'attenzione ai dettagli e al servizio clienti di alta qualità, Coinbar ti offrirà un'esperienza unica e indimenticabile. Non perdere l'opportunità di vivere questa nuova community e prenota subito un tavolo da Coinbar.</p>
              <p>{language.p}<a href="tel:3240473117">324 047 3117</a></p>

              <GreenButton href="https://wa.me/c/393240473117" >Scrivici su WhatsApp</GreenButton>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} className="hero-heading-box">
          <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img src={imageBar} style={{ width: "100%", height: "auto" }} />
            </Box>
          </Grid>



        </Grid>
      </section>
    </div>
  );
}

export default Prenota;
