import React from 'react'
import btc from '../../assets/cripto/bitcoin-green.png'
import eth from '../../assets/cripto/ethereum-green.png'
import dot from '../../assets/cripto/polkadot-green.png'
import matic from '../../assets/cripto/polygon-green.png'
import xrp from '../../assets/cripto/ripple-green.png'
import btcRed from '../../assets/cripto/bitcoin-red.png'
import ethRed from '../../assets/cripto/ethereum-red.png'
import dotRed from '../../assets/cripto/polkadot-red.png'
import maticRed from '../../assets/cripto/polygon-red.png'
import xrpRed from '../../assets/cripto/ripple-red.png'
import ltc from '../../assets/cripto/litecoin-green.png'
import ltcRed from '../../assets/cripto/litecoin-red.png'
import hudi from '../../assets/cripto/hudi-green.png'
import hudiRed from '../../assets/cripto/hudi-red.png'
import axs from '../../assets/cripto/axs-green.png'
import axsRed from '../../assets/cripto/axs-red.png'
import link from '../../assets/cripto/link-green.png'
import linkRed from '../../assets/cripto/link-red.png'
import bch from '../../assets/cripto/bch-green.png'
import bchRed from '../../assets/cripto/bch-red.png'
import uni from '../../assets/cripto/uni-green.png'
import uniRed from '../../assets/cripto/uni-red.png'
import "./tradingcard.scss";
function TradingCard(props) {
    const [sign, setSign] = React.useState("€")
    const [image, setImage] = React.useState("€")
    React.useEffect(() => {
        if(props.coppia.includes("USD")){
            setSign("$")
        }else if(props.coppia.includes("EUR")){
            setSign("€")
        }else if(props.coppia.includes("BTC")){
            setSign("₿")
        }
    }, [])
    const calculatePercentageDiff = () => {
        let currentPrice = (parseFloat(props.ask) + parseFloat(props.bid)) / 2
        let startPrice = currentPrice - parseFloat(props.perc)
        let endPrice = currentPrice
        const increase = new Number(endPrice) - new Number(startPrice);
        const percentagediff = ((increase / new Number(startPrice)) * 100).toFixed(2)
        return percentagediff
    }
    try{
        return (
            <div className={parseFloat(props.perc) > 0 ? "trading-card positive" : "trading-card negative"}>
                {/*<img src={parseFloat(props.perc) > 0 ? props.src[0] : props.src[1]} alt="Icona della coppia" />*/}
                <span className="title-pair">{props.coppia}</span>
                <div className="bid-ask">
                    <div className="ask">
                        <span>ASK</span>
                        <span>{sign} {props.ask}</span>
                    </div>
                    <div className="divider" />
                    <div className="bid" >
                        <span>BID</span>
                        <span >{sign} {parseFloat(props.bid)}</span>
                    </div>
                </div>
                <div className="info-trading">
                    <span>Last 24H</span>
                    <span>{parseFloat(props.perc) > 0 && "+"}{calculatePercentageDiff()}%</span>
                </div>
            </div>
        );
    }catch{
        <div/>
    }
    
}

export default TradingCard;
