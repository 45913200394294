import React from 'react';
import "./illustrazioni.scss"
import srcImg from "../../assets/downgraded/banner-01.png"
import srcImgWebP from "../../assets/banner-01.webp"
import Image from 'react-image-webp';

function IllustrazioneHero(props) {

    return (
        <Image
            id="hero3d" className="z-index-0 align-center"
            src={srcImg}
            webp={srcImgWebP}
            alt="Illustrazione 3D"
        />

    );
}

export default IllustrazioneHero;
