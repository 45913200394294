import React from 'react'
import "./privacy.scss"
import ita from './ita.json'
import eng from './eng.json'
function Privacy(props) {
  let language = props.isEng ? eng : ita
  language = language.privacy
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div id="Privacy" >
      <div className="hero-banner">
      </div>
      {
        props.isEng ?
          <section className="content">
            <h1>Privacy Policy</h1>

            <h2>&nbsp;</h2>
            <p><strong>COINBAR PRIVACY POLICY</strong></p>
            <p><span >To the kind attention of users or intermediaries using the Exit compliant service offered by Coinbar S.p.a., we would like to inform you that Coinbar cares a great deal about your privacy and, more generally, the protection of your personal data.</span></p>
            <p><span >The EU General Data Protection Regulation 2016/679 (GDPR) requires everyone to provide the following information on the processing of your personal data, pursuant to Articles 13 and 14 of the GDPR.</span></p>
            <p><span  >Processing of Personal Data' means any procedure concerning any 'information relating to an identified or identifiable natural person'. For example, First Name and Last Name, or an e-mail address with a 'username' identifying you (e.g. johnsmith@...) are considered 'Personal Data'.-</span></p>
            <p><span  >Your personal wallet, which contains the funds you transfer to Coinbar Wallet, may also include personal data about the owner. Collecting this type of data, recording it in our system and using it to offer you the Services provided by Coinbar, or even sending you a communication, falls within the definition of "processing" of personal data. Similarly, sharing personal information with other parties and storing it are examples of processing of personal information.</span></p>
            <p><span  >Please consult the website of the Garante per la protezione dei dati personali for a better understanding of this topic (e.g. https://www.garanteprivacy.it/web/guest/home_en ).</span></p>
            <p><span  >Coinbar S.p.a. is the "Data Controller", as far as operations involving the Financial Asset are concerned. It is therefore the entity that determines the purposes and methods of the processing of personal data relating to natural persons.</span></p>
            <p><span  >Every visitor and user of Coinbar is a "data subject", i.e. the natural person to whom the data relates, and has the right to be informed about who we are, what personal data we process, why we need it, how we process it and for how long. You also have the right to be informed about your obligations and rights.</span></p>
            <p><span  >Depending on the purpose for which you visit our site (simple Visitor - user of our Services), we collect and/or must ask you to provide us with certain Data that is necessary to use the Site and/or use our Services; when you visit the website, we collect information that does not allow direct identification and therefore we will not process any Personal Data, only navigation data.</span></p>
            <p><span  >The purpose of this privacy policy is also to inform you that if you use Coinbar's Exit compliant service, which involves the establishment of partnerships with national and international credit institutions, your data will be shared with the company from time to time involved and with which the settlement will take place in order to carry out all necessary procedures for AML compliance.</span></p>
            <p><span  >By accepting our privacy policy and signing the "letter of assignment" authorising Coinbar to carry out the exit operation, you freely and unequivocally consent to the sharing of your personal data with the credit institution involved in the exit operation, on the basis of the provisions of Article 7 of the GDPR.</span></p>
            <h2>&nbsp;</h2>
            <p><span  >You instruct Coinbar S.p.a., by means of an 'engagement letter', to carry out the market exit of your funds.&nbsp;</span></p>
            <p><span  >The credit institution involved in this transaction and expressly indicated by the customer will receive the equivalent in euro generated by the exit activity authorised by the customer.</span></p>
            <p><span  >The credit institution will take care of approving the transaction and keeping the reports generated by Coinbar containing personal data.</span></p>
            <p><span  >The definitions of the terms and expressions used in the table below are contained in the Privacy Glossary, available on this site.</span></p>
            <h2>&nbsp;</h2>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p><strong>Who are we? ('Data Controller')</strong></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >Coinbar S.p.A. (C.F. and P.IVA 14939301009), registered office in Via della Marcigliana 532, Rome</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><strong>What categories of personal data do we process?</strong></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><strong>Personal Data (e.g. Name and Surname, tax code, telephone number, address, etc.) to the minimum extent necessary to achieve the Purposes set out below;</strong></p>
                    <br />
                    <p><strong>Special categories of data: ex art. 9 of EU Regulation 2016/679;</strong></p>
                    <p><strong>Judicial data: ex art. 10 of EU Regulation 2016/679 (only when required by law).</strong></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h2>&nbsp;</h2>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p><strong>What is the source of your personal data?</strong></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >Normally we process the data you provide us with, in other cases, we may collect information from other parties or public sources.</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><strong>Why do we process Personal Data (Purpose) and on what basis (Legal Basis) do we process each category of data?</strong></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h2>&nbsp;</h2>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p><span  >N&deg;</span></p>
                  </td>
                  <td>
                    <p><span  >Purpose</span></p>
                  </td>
                  <td>
                    <p><span  >Categories of personal data</span></p>
                  </td>
                  <td>
                    <p><span  >Basic regulations</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >1</span></p>
                  </td>
                  <td>
                    <p><span  >To enable users to navigate the site</span></p>
                  </td>
                  <td>
                    <p><span  >Common - the information collected is Personal Data</span></p>
                  </td>
                  <td>
                    <p><span  >Legitimate interest of Coinba (Having the opportunity to offer our Services)</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >2</span></p>
                  </td>
                  <td>
                    <p><span  >To fulfil your requests in relation to our Services (including requests sent via the Contact section of www.coinbar.io).</span></p>
                  </td>
                  <td>
                    <p><span  >Municipalities</span></p>
                  </td>
                  <td>
                    <p><span  >The need to take action at the user's request before the conclusion of a contract.</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >3</span></p>
                  </td>
                  <td>
                    <p><span  >Purposes related to the provision of Services.</span></p>
                  </td>
                  <td>
                    <p><span  >Common and special data</span></p>
                  </td>
                  <td>
                    <p><span  >The need to perform the contract concluded with you and your Consent - which is mandatory - because if we do not acquire your Consent we may not be able to provide you with the Services.</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >4</span></p>
                  </td>
                  <td>
                    <p><span  >Marketing (for sending advertising material, commercial communication, direct sales and market research) to Lead and/or Prospect, as well as through the Newsletter.</span></p>
                  </td>
                  <td>
                    <p><span  >Municipalities</span></p>
                  </td>
                  <td>
                    <p><span  >Your consent - optional - freely given and revocable at any time.</span></p>
                    <br />
                    <p><span  >Failure to provide the data will result in the partial or total impossibility of achieving the above-mentioned purposes.</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >5</span></p>
                  </td>
                  <td>
                    <p><span  >Marketing (by means of the e-mail address provided, on Services similar to the one provided pursuant to Art. 130 paragraph 4 Privacy Code).</span></p>
                  </td>
                  <td><br /><br />
                    <p><span  >Municipalities (e-mail address)</span></p>
                  </td>
                  <td>
                    <p><span  >Legitimate interest (strengthening our business relationship with Users)</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >6</span></p>
                  </td>
                  <td>
                    <p><span  >Fulfilment of a legal obligation to which the data controller is subject and/or of requests by the authorities.</span></p>
                  </td>
                  <td>
                    <p><span  >Municipalities</span></p>
                  </td>
                  <td>
                    <p><span  >The need to perform the Contract or the need to fulfil legal obligations.</span></p>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>
                    <p><span  >Data sharing with national and international banks for AML compliance and commercial purposes</span></p>
                  </td>
                  <td>
                    <p><span  >Municipalities and specials</span></p>
                  </td>
                  <td>
                    <p><span  >The need to offer our services in full compliance with current anti-money laundering legislation</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >7</span></p>
                  </td>
                  <td>
                    <p><span  >Accepting, exercising and/or defending a right</span></p>
                  </td>
                  <td>
                    <p><span  >Common and when necessary special</span></p>
                  </td>
                  <td>
                    <p><span  >The legitimate interest in pursuing this jurisdictional aim</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >8</span></p>
                  </td>
                  <td>
                    <p><span  >Statistics. For this purpose, we will only use anonymous information (which does not allow us to identify Users)</span></p>
                  </td>
                  <td>
                    <p><span  >Anonymous information</span></p>
                  </td>
                  <td>
                    <p><span  >None, because anonymous information is not Personal Data.</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >9</span></p>
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></td>
                  <td>
                    <p><span  >Scientific survey purposes or statistical analysis of anonymous data, without the possibility of identifying the user. These analyses are useful for measuring website performance or data traffic and assessing its usability and interest or for marketing purposes such as sending promotional information, notices and updates on the latest offers/services, social, cultural and solidarity initiatives, which are sent via the traditional method of contact (mail and/or by phone call from our operators) or automatic (e-mail, fax, SMS, mms);</span></p>
                  </td>
                  <td>
                    <p><span  >Municipalities</span></p>
                  </td>
                  <td>
                    <p><span  >Coinbar's legitimate interest (to have the opportunity to present its services), as well as your consent, freely given and revocable at any time.</span></p>
                    <br />
                    <p><span  >Failure to provide the data will result in the partial or total impossibility of achieving the above-mentioned purposes.</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h2>&nbsp;</h2>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p><span  >NB Personal data will not be processed for purposes other than those stated above. Should we process the data for different purposes, we will inform you and ask for your consent when necessary.</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >With whom do we share data (target groups)?</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >To the minimum extent necessary to achieve any Purpose, we share the data provided with the following target groups:</span></p>
                    <br />
                    <p><span  >Subjects with whom we cooperate for the performance of the contract, acting in their capacity as data processors or autonomous data controllers.</span></p>
                    <p><span  >Other persons whom we have authorised (e.g. our employers) and who have committed themselves to confidentiality or who meet a legal obligation of confidentiality;</span></p>
                    <p><span  >Public Bodies or Authorities, as provided for in Applicable Law or in response to requests from such Authorities, or to exercise or defend a right in court.</span></p>
                    <br />
                    <p><span  >There is no dissemination of Personal Data, unless required, in accordance with the law, by authorities, information and security bodies or other public entities for the protection or security of the State or for the prevention, investigation or prosecution of criminal offences.</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >How long do we keep your data?</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >Personal Data will be processed by our authorised internal staff, duly trained. The data will be processed, from the moment they are acquired or updated, in accordance with the principles of proportionality and necessity and for the time strictly necessary to achieve the purposes indicated above, and in any case, unless otherwise provided for by the interested parties or by law, for a maximum of 24 months, after which the data will be deleted and destroyed.</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >Does the website use cookies?</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >Yes. To learn more and to view our Cookie policy, please see the Cookie Policy</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >Are you obliged to provide us with Personal Data?</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >Browsing data (which is usually not Personal Data) is necessary to navigate the Coinbar website.</span></p>
                    <br />
                    <p><span  >Visitors are, of course, not obliged to use our services, to write to us via the contact page, to subscribe to the newsletter, but if they wish to do so, they must provide us with the Personal Data requested.</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >What happens if you refuse to provide us with your Data?</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >Visitors cannot refuse to provide navigation data. Refusal to install cookies will not allow the site to function properly.</span></p>
                    <br />
                    <p><span  >If Users refuse to provide the requested data, we will not be able to perform and/or proceed with the Contract and provide our Services</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >Transfer of data abroad</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >Coibar.co.uk may transfer some Personal Data abroad to recipients located outside the European Economic Area. We guarantee that the processing (in electronic or paper form) of Personal Data by the Recipient will comply with EU Regulation 2016/679 and, in particular, that transfers are based on the adequacy decision or Standard Model Clauses, approved by the European Commission. You can access the list of External Processors by contacting Coinbar.co.uk</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >What rights do data subjects have?</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >You have the right to:</span></p>
                    <br />
                    <ol>
                      <li   aria-level="1"><span  >have access to the Personal Data we hold</span></li>
                      <li   aria-level="1"><span  >Requesting the rectification of incomplete or inaccurate Personal Data</span></li>
                      <li   aria-level="1"><span  >Request cancellation when the conditions for exercising this right are met.</span></li>
                      <li   aria-level="1"><span  >Request the restriction of processing, when the conditions for exercising this right are met,</span></li>
                      <li   aria-level="1"><span  >oppose processing for Marketing Purposes, for purposes related to your particular situation;</span></li>
                      <li   aria-level="1"><span  >You object to processing for Marketing Purposes by not giving consent or by revoking it later (if Lead or Prospect).</span></li>
                      <li   aria-level="1"><span  >Request the list of the Data Processors, together with the data useful for their identification.</span></li>
                      <li   aria-level="1"><span  >To receive one's own personal data and transmit such data to another data controller, when the conditions for exercising this right are met and when technically possible.</span></li>
                      <li   aria-level="1"><span  >File a complaint with the Garante per la protezione dei dati personali (in Italy, www.garanteprivacy.it), or with the Garante per la protezione dei dati personali of the EU Member State where you usually reside or work, or of the place where the breach occurred.</span></li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >If you have doubts or questions about the processing of your Data, what can you do?</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span  >Please forward your requests to the following email address compliance@coinbar.io or by post to the address: Coinbar Spa, Via della Marcigliana, 352, Rome - Italy</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h2>&nbsp;</h2>
            <p><span  >This Privacy Policy is effective as of 25 June 2019; Coinbar S.p.a. reserves the right to change its content, in whole or in part, including in response to changes in the Privacy Regulations. We will release the updated version of the Privacy Policy on the Site and it will be mandatory from then on.</span></p>
            <p><span  >You are invited to review this page regularly.</span></p>
            <h2><br /><br /><br /><br /><br /></h2>
          </section>
          :
          <section className="content">
            <h1>Privacy Policy</h1>
            <p>{language.p[0]}</p>
            <p>{language.p[1]}</p>
            <p>{language.p[2]}</p>
            <p>{language.p[3]}</p>
            <p>{language.p[4]}</p>
            <p>{language.p[5]}</p>
            <p>{language.p[6]}</p>
            <p>{language.p[7]} <a href="/glossario-privacy">{language.a[0]}</a>.</p>
            <table width="662">
              <tbody>
                <tr>
                  <td colspan="4" width="662">
                    <strong>{language.strong[0]}</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    {language.colonna[0]}
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    <strong>{language.strong[1]}</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    <p><strong>{language.strong[2]}</strong>{language.colonna[1]}</p>
                    <p>&nbsp;</p>
                    <p><strong>{language.strong[3]}</strong> {language.colonna[2]}</p>
                    <p>&nbsp;</p>
                    <p><strong>{language.strong[4]}</strong> {language.colonna[3]}</p>
                    <p>&nbsp;</p>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    <strong>{language.strong[5]}</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">{language.colonna[4]}</td>
                </tr>
                <tr>
                  <td colspan="4" width="662"><strong>{language.strong[6]}</strong></td>
                </tr>
                <tr>
                  <td width="25">
                    <strong>n.</strong></td>
                  <td width="205"><strong>{language.strong[7]}</strong></td>
                  <td width="198"><strong>{language.strong[8]}</strong></td>
                  <td width="234"><strong>{language.strong[9]}</strong></td>
                </tr>
                <tr>
                  <td width="25"><strong>1</strong></td>
                  <td width="205">{language.colonna[5]}</td>
                  <td width="198">{language.colonna[6]}</td>
                  <td width="234">{language.colonna[7]}</td>
                </tr>
                <tr><td width="25"><strong>2</strong></td>
                  <td width="205">{language.colonna[8]}<a href="http://www.coinbar.io">{language.a[1]}</a>).</td>
                  <td width="198">{language.colonna[9]}</td>
                  <td width="234">{language.colonna[10]}</td>
                </tr>
                <tr>
                  <td width="25"><strong>3</strong></td>
                  <td width="205">{language.colonna[11]}</td>
                  <td width="198">{language.colonna[12]}</td>
                  <td width="234">{language.colonna[13]}</td>
                </tr>
                <tr><td width="25"><strong>4</strong></td>
                  <td width="205">{language.colonna[14]}</td>
                  <td width="198">{language.colonna[15]}</td>
                  <td width="234">
                    <p>{language.colonna[16]}</p><p>&nbsp;</p>
                    <p>{language.colonna[17]}</p>
                  </td>
                </tr>
                <tr>
                  <td width="25"><strong>5</strong></td>
                  <td width="205">{language.colonna[18]}</td>
                  <td width="198">{language.colonna[19]}</td>
                  <td width="234">{language.colonna[20]}</td>
                </tr>
                <tr>
                  <td width="25"><strong>6</strong></td>
                  <td width="205">{language.colonna[21]}</td>
                  <td width="198">{language.colonna[22]}</td>
                  <td width="234">{language.colonna[23]}</td>
                </tr>
                <tr>
                  <td width="25"><strong>7</strong></td>
                  <td width="205">{language.colonna[24]}</td>
                  <td width="198">{language.colonna[25]}</td>
                  <td width="234">{language.colonna[26]}</td>
                </tr>
                <tr>
                  <td width="25"><strong>8</strong></td>
                  <td width="205">{language.colonna[27]}</td>
                  <td width="198">{language.colonna[28]}</td>
                  <td width="234">{language.colonna[29]}</td>
                </tr>
                <tr>
                  <td width="25"><strong>9</strong></td>
                  <td width="205">{language.colonna[30]}</td>
                  <td width="198">{language.colonna[31]}</td>
                  <td width="234">
                    <p>{language.colonna[32]}</p><p>&nbsp;</p>
                    <p>{language.colonna[33]}</p>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    {language.colonna[34]}
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    <strong>{language.strong[10]}</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    <p>{language.colonna[35]}</p><p>&nbsp;</p>
                    <p>{language.colonna[36]}</p>
                    <p>{language.colonna[37]}</p>
                    <p>{language.colonna[38]}</p>
                    <p>&nbsp;</p>
                    <p>{language.colonna[39]}</p>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    <strong>{language.strong[11]}</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    {language.colonna[40]}
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    <strong>{language.strong[12]}</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    {language.colonna[41]}
                    <a href="https://coinbar.io/cookies-policy">Cookie Policy.</a>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    <strong>{language.strong[13]}</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    <p>{language.colonna[42]}</p>
                    <p>&nbsp;</p>
                    <p>{language.colonna[43]}</p>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    <strong>{language.strong[14]}</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    <p>{language.colonna[44]}</p>
                    <p>&nbsp;</p>
                    <p>{language.colonna[45]}</p>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    <strong>{language.strong[15]}</strong></td>
                </tr>
                <tr>
                  <td colspan="4" width="662">{language.colonna[46]}</td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    <strong>{language.strong[16]}</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    <p>{language.colonna[46]}</p>
                    <p>&nbsp;</p>
                    <p>{language.colonna[47]}</p>
                    <p>{language.colonna[48]}</p>
                    <p>{language.colonna[49]}</p>
                    <p>{language.colonna[50]}</p>
                    <p>{language.colonna[51]}</p>
                    <p>{language.colonna[52]}</p>
                    <p>{language.colonna[53]}</p>
                    <p>{language.colonna[54]}</p>
                    <p>
                      {language.colonna[55]}
                      <a href="http://www.garanteprivacy.it/">www.garanteprivacy.it</a>)
                      {language.colonna[56]}
                      <a href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">{language.a[2]}</a>
                      {language.colonna[57]}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    <strong>{language.strong[17]}</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" width="662">
                    {language.colonna[47]}

                    <a href="mailto:privacy@coinbar.io">privacy@coinbar.io</a>
                    {language.colonna[48]}
                  </td>
                </tr>
              </tbody>
            </table>
            <p>{language.nb}</p>
          </section>
      }
    </div>
  );

}

export default Privacy;
