import React from 'react'
import Hero from "../../Layouts/Hero/Hero"
import "./landingExchange.scss"
import SlideShow from "../../Components/SlideShow/SlideShow";
import bitcoin from "../../assets/bitcoin.gif"
import key1 from "../../assets/key-value-1.png"
import key2 from "../../assets/key-value-2.png"
import key3 from "../../assets/key-value-3.png"
import key4 from "../../assets/key-value-4.png"
import Button from "../../Components/Button/Button";
import Section from "../../Layouts/Section/Section";
import TradingCard from '../../Components/Grafico/TradingCard';
import IllustrazioneExchange from '../../Components/Illustrazioni/IllustrazioneExchange';
import SectionPartners from '../../Sections/SectionPartners/SectionPartners';
import SectionBar from '../../Sections/SectionBar/SectionBar';
import SectionJob from '../../Sections/SectionJob/SectionJob';
import btc from '../../assets/cripto/bitcoin-green.png'
import eth from '../../assets/cripto/ethereum-green.png'
import dot from '../../assets/cripto/polkadot-green.png'
import matic from '../../assets/cripto/polygon-green.png'
import xrp from '../../assets/cripto/ripple-green.png'
import btcRed from '../../assets/cripto/bitcoin-red.png'
import ethRed from '../../assets/cripto/ethereum-red.png'
import dotRed from '../../assets/cripto/polkadot-red.png'
import maticRed from '../../assets/cripto/polygon-red.png'
import xrpRed from '../../assets/cripto/ripple-red.png'
import ltc from '../../assets/cripto/litecoin-green.png'
import ltcRed from '../../assets/cripto/litecoin-red.png'
import hudi from '../../assets/cripto/hudi-green.png'
import hudiRed from '../../assets/cripto/hudi-red.png'
import axs from '../../assets/cripto/axs-green.png'
import axsRed from '../../assets/cripto/axs-red.png'
import link from '../../assets/cripto/link-green.png'
import linkRed from '../../assets/cripto/link-red.png'
import bch from '../../assets/cripto/bch-green.png'
import bchRed from '../../assets/cripto/bch-red.png'
import uni from '../../assets/cripto/uni-green.png'
import uniRed from '../../assets/cripto/uni-red.png'
import { Helmet } from 'react-helmet';
import ita from './ita.json'
import eng from './eng.json'
const axios = require('axios').default;

function FallbackTC(props) {
  let language = props.isEng ? eng : ita
  React.useEffect(() => {
    console.log("prezzi=>", prezzi)
  }, [])

  let prezzi = props.prezzi
  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  }
  const tryCatch = (callback) => {
    try {
      callback()
      console.log("try")
    }
    catch {
      return null
      console.log("nooo")
    }
  }

  if (!prezzi) {
    return (

      <div className="trading-cards" />



    );
  } else {
    return (
      <div className="trading-cards">
        {
          prezzi.length>0&&prezzi.map(coppia => (
            <TradingCard  price={coppia}
              coppia={coppia.pair}
              perc={coppia.price_24h_change}
            />

          ))
        }
      
      </div>
    )
  }
}

export default FallbackTC;
