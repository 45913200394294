import React from 'react';
import Hero from "../../Layouts/Hero/Hero"
import "./landingBar.scss"
import Button from "../../Components/Button/Button";
import Map from "../../Components/Map/Map";
import Section from "../../Layouts/Section/Section";
import SectionExchange from '../../Sections/SectionExchange/SectionExchange';
import SectionPartners from '../../Sections/SectionPartners/SectionPartners';
import SectionJob from '../../Sections/SectionJob/SectionJob';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Carosello from '../../Components/Carosello/Carosello';
import ita from './ita.json'
import eng from './eng.json'
function LandingBar(props) {
  let language = props.isEng ? eng : ita
  let navigate = useNavigate();
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const goToPage = (arg) => {
    navigate("../" + arg, { replace: true })
  }

  return (
    <div id="LandingBar" >
{
  props.isEng? 
  <Helmet>
        <title>Coinbar | The Bar | Via Barberini 34</title>
        <meta name="title" content="Coinbar | The Bar | Via Barberini 34" />
        <meta name="description" content="Coinbar, in addition to being a cryptocurrency exchange, is also the first Italian venue for the crypto community! Come and visit us at Via Barberini 34, you will meet crypto enthusiasts and you will be able to network during our events." />
        <meta name="keywords" lang="eng" content="exchange, criptovalute, bar, crypto, coinbar, networking" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      :
      <Helmet>
        <title>Coinbar | Il locale | Via Barberini 34</title>
        <meta name="title" content="Coinbar | Il locale | Via Barberini 34" />
        <meta name="description" content="Coinbar, oltre ad essere un exchange di criptovalute è anche il primo locale italiano per la community crypto! Vieni a trovarci a Via Barberini 34, incontrerai appassionati di crypto e potrai fare networking durante i nostri eventi." />
        <meta name="keywords" lang="it" content="exchange, criptovalute, bar, crypto, coinbar, networking" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="Italian" />
      </Helmet>
}
      <Hero>
        <div id="HeroBanner" className="flex-container vh-100 justify-center flex-column ">
          <div className="flex-1 flex-container  flex-column align-center justify-center  base-padding-side ">
            <h1 className="text-white z-index-1 max-width-50   hero-heading text-center">{language.h1}</h1>
            <p className="text-white z-index-1 max-width-50 text-center">{language.subtitle}</p>
          </div>
        </div>
      </Hero>
      <Section id="About" >
        <div className="flex-container gap-50 margin-top-100 margin-bottom-200 column-on-mobile  double-base-padding-side">
          <div className="flex-1 flex-container flex-column  align-items-center">
            <div className="img-sldr" >
              <div className="sld-1" />
            </div>
          </div>
          <div className="flex-1  flex-container flex-column align-items-start">
            <h3 className="sub-heading text-center">{language.h3[0]}</h3>
            <p >{language.p[0]}</p>
            <h3 className="sub-heading text-center">{language.h3[1]}</h3>
            <p > {language.p[1]}</p>
            <h3 className="sub-heading text-center">{language.h3[2]}</h3>
            <p >{language.p[2]}</p>
            <h3 className="sub-heading text-center">{language.h3[3]}</h3>
            <p >{language.p[3]}</p>

            <div className="button-stacks margin-top-50">
              <Button white={false} link="/bar/menu" interno={false}>{language.button[0]}</Button>
              <Button white={false} outline={true} link="/bar/prenota" interno={false}>{language.button[1]}</Button>
            </div>
          </div>
        </div>

        <div className="flex-container gap-50 margin-top-100 margin-bottom-200 column-on-mobile  double-base-padding-side">
          <div className="flex-1 flex-container flex-column  align-items-center">
            <div className="img-sldr" >
              <div className="sld-2" />
            </div>
          </div>
          <div className="flex-1  flex-container flex-column align-items-start">
            <h3 className="sub-heading text-center">{language.h3[4]}</h3>
            <p >
              {language.p[4]}
              <br />
              {language.p[5]}
              <br />
              {language.p[6]}
            </p>
            <div className="button-stacks margin-top-50">
              <Button white={false} link="bar/evento" interno={false}>{language.button[2]}</Button>
            </div>
          </div>
        </div>
        <div >
          <Carosello />
        </div>
        <div className="flex-container gap-50 margin-top-100 margin-bottom-200 column-on-mobile  double-base-padding-side">
          <div className="flex-1 flex-container flex-column  align-items-center">
            <Map />
          </div>
          <div className="flex-1  flex-container flex-column align-items-start">
            <h2 className="sub-heading text-center">{language.h2}</h2>
            <p className="">{language.p[7]}</p>
            <div className="button-stacks margin-top-50">
              <Button white={false} link="/bar/prenota" interno={true}>{language.button[1]}</Button>
            </div>
          </div>
        </div>
      </Section>
      <SectionExchange  isEng={props.isEng}/>
      <SectionPartners  isEng={props.isEng} />
      <SectionJob  isEng={props.isEng}/>
    </div>
  );
}

export default LandingBar;
