import React from 'react';
import "./illustrazioni.scss"
import srcImg from "../../assets/downgraded/banner-02.png"
import srcImgWebP from "../../assets/banner-02.webp"
import Image from 'react-image-webp';

function IllustrazioneExchange(props) {

    return (
        <Image
            id="computer3d"
            className="z-index-0"
            src={srcImg}
            webp={srcImgWebP}
            alt="Illustrazione 3D"
        />
    );
}

export default IllustrazioneExchange;
