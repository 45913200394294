import React from 'react'
import "./evento.scss"
import FormOrganizzaEvento from '../../Components/Forms/FormOrganizzaEvento';
import ita from './ita.json'
import eng from './eng.json'
function Evento(props) {
  let language = props.isEng ? eng : ita
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div id="Evento" >
      <div className="hero-banner">
      </div>
      <section id="form-evento">
        <h1>{language.h1}</h1>
        <p>
          {language.subtitle[0]}
          <br />
          {language.subtitle[1]} </p>
        <FormOrganizzaEvento isEng={props.isEng} />
      </section>
    </div>
  );
}

export default Evento;
