import React from 'react';
import Hero from "../../Layouts/Hero/Hero"
import "./sectionbar.scss"
import Button from "../../Components/Button/Button";
import ita from './ita.json'
import eng from './eng.json'

function SectionBar(props) {
    let language = props.isEng ? eng : ita

    return (

        <Hero>
            <div id="bar-section" className="flex-container vh-100 double-base-padding-side justify-center">
                <div className="flex-1 align-center max-width-70">
                   
                    <h2 className="text-center  hero-heading">{language.h2}</h2>
                    <p className="text-center">
                        {language.p}
                    </p>
                    <div className="margin-top-50"><Button white={true} link="bar" interno={false}>{language.button}</Button></div>

                </div>
            </div>
        </Hero>

    );
}

export default SectionBar;
