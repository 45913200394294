import React from "react";
import "./privacy.scss";
import { Formik } from "formik";

function Commissioni(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="Commissioni">
      <div className="hero-banner"></div>
      {props.isEng ? (
        <section className="content">
          <h1>Commissions</h1>
          <h2>Info and user levels</h2>
          <h3>Calculation of commissions</h3>
          <p>They are applied to the currency object of the order</p>
          <h3>User level rules</h3>
          <ul>
            <li>
              User levels, also called Tier, regulate the limits of the volumes
              that a user can perform over a day or a year.{" "}
            </li>
            <li>
              {" "}
              The New User level allows viewing of the platform features, but
              does not enable operations
            </li>
            <li>
              The Tier2 level is applied following confirmation of user
              verification (KYC) and AML
            </li>
            <li>
              Levels from Tier3 onwards are assigned based on the correspondence
              between income and transactions performed (AML verifications),
              following the user request
            </li>
          </ul>
          <table>
            <thead>
              <tr>
                <td>
                  <span>
                    <strong>
                      &nbsp;<span>Level</span>
                    </strong>
                  </span>
                </td>
                <td>
                  <span>
                    <strong>
                      &nbsp;<span>Annual Limit(€)</span>
                    </strong>
                  </span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>New User</td>
                <td>Display Only</td>
              </tr>
              <tr>
                <td>Tier1</td>
                <td>
                  up to € 15,000<em>(based on annual income)</em>
                </td>
              </tr>
              <tr>
                <td>Tier2</td>
                <td>
                  up to € 50.000<em>(based on annual income)</em>
                </td>
              </tr>
              <tr>
                <td>Tier3</td>
                <td>
                  up to € 130.000 <em>(based on annual income)</em>
                </td>
              </tr>
              <tr>
                <td>Tier4</td>
                <td>Based on financial capacity</td>
              </tr>
              <tr>
                <td>Business</td>
                <td>
                  <a href="https://coinbar.io/#/contatti">
                    Request information
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <h2>Trading Commissions</h2>
          <h3>General Info</h3>
          <p> Trading fees fall into two categories :</p>
          <ul>
            <li>
              Maker : When you place an order that is not immediately processed,
              because it does not meet the current conditions of the book, such
              as a limit order, the Maker order is defined.
            </li>
            <li>
              Taker : It is defined as such when an order is entered that is
              immediately processed, such as an order to market.
            </li>
          </ul>
          <table>
            <thead>
              <tr>
                <td>
                  <span>
                    <strong>&nbsp;Kind</strong>
                  </span>
                </td>
                <td>
                  <span>
                    <strong>&nbsp; Maker Commission</strong>
                  </span>
                </td>
                <td>
                  <span>
                    <strong>&nbsp; Taker Commission</strong>
                  </span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Crypto-Crypto</td>
                <td>0.80%</td>
                <td>0.80%</td>
              </tr>
              <tr>
                <td>Fiat-Crypto</td>
                <td>0.80%</td>
                <td>0.80%</td>
              </tr>
              <tr>
                <td>OTC</td>
                <td style={{ textAlign: "center" }}>Starting from 1,5%</td>
              </tr>
            </tbody>
          </table>
          <h3>Account business</h3>
          <p>
            If you are an institutional trader, or a high volume trading trader,
            contact us for more advantageous commissions
          </p>
          <h2>Deposit / Withdrawal Commissions</h2>
          <p>
            Deposits have no commissions The minimum amount for fiat deposits is
            € 100 There are no minimums for crypto deposits
          </p>
          <table>
            <thead>
              <tr>
                <td>
                  <strong>
                    <span>&nbsp;Currency</span>
                  </strong>
                </td>
                <td>
                  <strong>
                    <span>&nbsp;Commission</span>
                  </strong>
                </td>
                <td>
                  <strong>
                    <span>&nbsp;Minimum withdrawal</span>
                  </strong>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>BTC</td>
                <td>
                  <span>1.00</span>%
                </td>
                <td>0.01 BTC</td>
              </tr>
              <tr>
                <td>ETH</td>
                <td>
                  <span>1.00</span>%
                </td>
                <td>0.5 ETH</td>
              </tr>
              <tr>
                <td>XRP</td>
                <td>
                  <span>1.00</span>%
                </td>
                <td>100 XRP</td>
              </tr>
              <tr>
                <td>LTC</td>
                <td>
                  <span>1.00</span>%
                </td>
                <td>1 LTC</td>
              </tr>
              <tr>
                <td>USDC</td>
                <td>
                  <span>1.00</span>%
                </td>
                <td>50 USDC</td>
              </tr>
              <tr>
                <td>BCH</td>
                <td>
                  <span>1.00</span>%
                </td>
                <td>0.5 BCH</td>
              </tr>
              <tr>
                <td>EUR</td>
                <td>
                  5€ + <span>2.00</span>% <br />
                  *For Tier 1 it is only 1.00%
                </td>
                <td>100 €</td>
              </tr>
            </tbody>
          </table>
        </section>
      ) : (
        <section className="content">
          <h1>Commissioni</h1>
          <h2>Informazioni e livelli utente</h2>
          <h3>Calcolo commissioni</h3>
          <p>Vengono applicate sulla valuta oggetto dell’ordine</p>
          <h3>Regole livelli utenti</h3>
          <ul>
            <li>
              {" "}
              I livelli utente, anche chiamati Tier, regolano i limiti dei
              volumi che un utente può eseguire nell’arco di un giorno o un
              anno.
            </li>
            <li>
              {" "}
              Il livello Nuovo Utente consente la visualizzazione delle
              funzionalità della piattaforma, ma non abilita l’operatività
            </li>
            <li>
              Il livello Tier2 viene applicato a seguito della conferma della
              verificà dell’utente (KYC) e AML
            </li>
            <li>
              I livelli dal Tier3 in poi vengono assegnati in base alla
              corrispondenza tra il reddito e le transazioni eseguite (Verifiche
              AML), a seguito della richiesta utente
            </li>
          </ul>
          <table>
            <thead>
              <tr>
                <td>
                  <span>
                    <strong>
                      &nbsp;<span>Livello</span>
                    </strong>
                  </span>
                </td>
                <td>
                  <span>
                    <strong>
                      &nbsp;<span>Limite Annuale(€)</span>
                    </strong>
                  </span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Nuovo utente</td>
                <td>Solo visualizzazione</td>
              </tr>
              <tr>
                <td>Tier1</td>
                <td>
                  fino a € 15.000<em>(in base al reddito annuo)</em>
                </td>
              </tr>
              <tr>
                <td>Tier2</td>
                <td>
                  fino a € 50.000<em>(in base al reddito annuo)</em>
                </td>
              </tr>
              <tr>
                <td>Tier3</td>
                <td>
                  fino a € 130.000 <em>(in base al reddito annuo)</em>
                </td>
              </tr>
              <tr>
                <td>Tier4</td>
                <td>Basato sulla capacità finanziaria</td>
              </tr>
              <tr>
                <td>Business</td>
                <td>
                  <a href="https://coinbar.io/#/contatti">
                    Richiedi informazioni
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <h2>Commissioni Trading</h2>
          <h3>Informazioni generali</h3>
          <p> Le commissioni di trading si dividono in due categorie :</p>
          <ul>
            <li>
              Maker : Quando si inserisce un ordine che non viene immediatamente
              processato, perché non soddisfa le condizioni attuali del book,
              come per esempio un limit order, si definisce l’ordine Maker.
            </li>
            <li>
              Taker : Si definisce tale quando si inserisce un ordine che viene
              immediatamente processato, come per esempio un ordine a market.
            </li>
          </ul>
          <table>
            <thead>
              <tr>
                <td>
                  <span>
                    <strong>&nbsp;Tipo</strong>
                  </span>
                </td>
                <td>
                  <span>
                    <strong>&nbsp;Commissione Maker</strong>
                  </span>
                </td>
                <td>
                  <span>
                    <strong>&nbsp;Commissione Taker</strong>
                  </span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Crypto-Crypto</td>
                <td>0.80%</td>
                <td>0.80%</td>
              </tr>
              <tr>
                <td>Fiat-Crypto</td>
                <td>0.80%</td>
                <td>0.80%</td>
              </tr>
              <tr>
                <td>OTC</td>
                <td style={{ textAlign: "center" }}>Starting from 1,5%</td>
              </tr>
            </tbody>
          </table>
          <h3>Account business</h3>
          <p>
            Se sei un trader istituzionale, o ad alto volume di scambio,
            contattaci per avere commissioni più vantaggiose
          </p>
          <h2>Commissioni Depositi/Prelievi</h2>
          <p>
            I depositi non hanno commissioni L’importo minimo per i depositi
            fiat è di €100 Non ci sono minimi per depositi crypto
          </p>
          <table>
            <thead>
              <tr>
                <td>
                  <strong>
                    <span>&nbsp;Currency</span>
                  </strong>
                </td>
                <td>
                  <strong>
                    <span>&nbsp;Commissione</span>
                  </strong>
                </td>
                <td>
                  <strong>
                    <span>&nbsp;Prelievo minimo</span>
                  </strong>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>BTC</td>
                <td>
                  <span>1.00</span>%
                </td>
                <td>0.01 BTC</td>
              </tr>
              <tr>
                <td>ETH</td>
                <td>
                  <span>1.00</span>%
                </td>
                <td>0.5 ETH</td>
              </tr>
              <tr>
                <td>XRP</td>
                <td>
                  <span>1.00</span>%
                </td>
                <td>100 XRP</td>
              </tr>
              <tr>
                <td>LTC</td>
                <td>
                  <span>1.00</span>%
                </td>
                <td>1 LTC</td>
              </tr>
              <tr>
                <td>USDC</td>
                <td>
                  <span>1.00</span>%
                </td>
                <td>50 USDC</td>
              </tr>
              <tr>
                <td>BCH</td>
                <td>
                  <span>1.00</span>%
                </td>
                <td>0.5 BCH</td>
              </tr>
              <tr>
                <td>EUR</td>
                <td>
                  5€ + <span>2.00</span>% <br />
                  *Per i Tier 1 è solamente 1.00%
                </td>
                <td>100 €</td>
              </tr>
            </tbody>
          </table>
        </section>
      )}
    </div>
  );
}

export default Commissioni;
