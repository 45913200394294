import React from 'react'
import "./privacy.scss"
import { Formik } from 'formik';

function TOSCBPay(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div id="TOSCBPay" >
      <div className="hero-banner">
      </div>
      {
        props.isEng ?

          <section class="content">
            <h1>AGREEMENT REGARDING THE TERMS OF SERVICE OF COINBARPAY POS</h1>
            <ol>
              <li><div>
                <h3>FOREWORD</h3>
                <p>
                  These terms of use constitute the contract ("Contract") between the merchant ("Merchant") who decides to adopt the COINBARPay POS service and COINBAR S.p.a. ("COINBAR") (collectively, the "Parties").
                  <br />
                  Unless otherwise provided, any reference to the Contract also constitutes a reference to any applicable addendum, such as general terms and conditions, privacy policy, and cookies policy.
                </p>
                <p>
                  COINBAR SPA, Codice Fiscale/P. IVA 14939301009 è una società con sede legale in Via della Marcigliana n.532, Roma, rappresentata legalmente dal dott. Cugusi Antonello, di seguito denominata il “Fornitore” ed è iscritta presso la sezione speciale del registro degli operatori di Valute Virtuali (VASP) tenuto dall’Organismo Agenti e Mediatori, con  n. di iscrizione PSV16.
                </p>
                <p>
                  COINBAR SPA, Tax Code/P. VAT 14939301009 is a company with registered office in Via della Marcigliana n.532, Rome, legally represented by dr. Cugusi Antonello, hereinafter referred to as the "Provider" and is registered with the special section of the register of Virtual Currency Operators (VASP) maintained by the Body of Agents and Brokers, under no. PSV16 enrollment.<br />
                  The COINBARPay POS service is reserved exclusively for natural and legal persons and their intermediaries acting within the scope of their trade, business, craft or profession and who are established or have a branch in one of the countries that are part of the European Economic Area ("EEA").
                  <br />
                  The obligations covered by this Agreement shall be deemed to apply and enforceable, where applicable, to each user who is designated by a Merchant to use COINBARPay's services as set forth below.
                  <br />
                  Should the Merchant require further information about COINBARPay and/or the services offered by COINBAR S.p.a. can refer to our coinbar.io website or alternatively send an email to the following addresses:
                </p>
                <ul>
                  <li>info@coinbar.io</li>
                  <li>business@coinbar.io</li>
                </ul>
              </div></li>
              <li><div>
                <h3> SERVICE COVERED BY THE CONTRACT</h3>
                <p>
                  The service will be conducted exclusively through the online mode.<br />
                  The Coinbar company offers:
                </p>
                <ul>
                  <li><div>
                    <p>The COINBAR-PAY serivce: allows merchant/business type customers to be able to handle digital payments with cryptocurrencies and fiat currency (euro) exchange;</p>
                  </div></li>
                </ul>
                <h5>COINBAR-PAY</h5>
                <p>
                  The ability to accept cryptocurrency payments and convert them to FIAT currency is made possible through a process called "crypto-to-fiat conversion" integrated within the Coinbar Pay platform.
                  This function is especially useful for traders who do not want to withhold
                  cryptocurrency and prefer to have their funds in fiat currency. It also helps the
                  traders to avoid the volatility of the cryptocurrency market. It also enables the
                  merchants to easily account for their income in their local currency and avoid
                  any conversion fees when using their funds.

                </p>
                <p>
                  The service will be delivered with a custom Coinbar Pay webapp integrated via API to e-commerce and through dedicated Android App for use as an in-store POS.
                </p>
                <p>
                  The merchant's customer is guided through crypto payment, being able to use two methods:
                </p>
                <ol>
                  <li>private wallet,</li>
                  <li>coinbar account.</li>
                </ol>
                <p>
                  In case of payment via external wallet, the following cryptocurrencies will be available:
                </p>
                <ul>
                  <li>BTC</li>
                  <li>USDT</li>
                  <li>XDB</li>
                  <li>XRP</li>
                </ul>
                <p>
                  Additional cryptocurrencies will be made available by Coinbar in subsequent updates.<br />
                  Using Coinbar's internal account payment method, however, all cryptocurrencies currently on the platform are usable.
                  <br />
                  Merchant customers will be able to make payments either by resorting to an external wallet in their name or by registering on the Coinbar platform.
                </p>
              </div></li>
              <li><div>
                <h3>MODE OF CONDUCT OF COINBARPAY SERVICE.</h3>
                <p>
                  Here we briefly outline the flow according to which the service is developed, as for further technical details the merchant can request at the email address business@coinbar.io to consult the technical attachment CoinbarPay Integration Preview.pdf
                </p>
                <p>Unfolding:</p>
                <ol>
                  <li>
                    <h5>Account creation procedure</h5>
                    <ul>
                      <li>the company accepting payments through COINBARPAY proceeds to create its own account at https://coinbar.io/ in order to activate the service;</li>
                      <li>follows the KYC verification procedure according to the internal protocol adopted by Coinbar;</li>
                      <li>at the same time the client company activates the exchange service by approving the terms and conditions of the platform available at the following link https://coinbar.io/terms-account;</li>
                    </ul>
                  </li>
                  <p>AFTER THE PREPARATION AND APPROVAL OF THE NECESSARY ACCOUNTS IS COMPLETED, THE SERVICE BECOMES OPERATIONAL AND IS DEVELOPED AS FOLLOWS:</p>
                  <li>
                    <h5>Service execution and payment management:</h5>
                    <ul>
                      <li>
                        Payment is made through a web request from the "merchant platform" to Coinbar Pay, with input the data of the user, the product being purchased, the cost in euros, and other custom data.
                      </li>
                      <li>
                        Opening the Coinbar page that displays the summary of the payment request and the choice of one of the two managed modes.
                      </li>
                      <li>The user chooses the payment mode between private wallet and Coinbar account.</li>
                    </ul>
                    <p>Payment management is described within Section 4.4 ff of the Technical Annex forming an integral part of the contract, that could be requested with mail to:<br /> compliance@coinbar.io</p>
                  </li>
                  <li>
                    <h5>Business Dashboard</h5>
                    <p>Through the COINBARPay application, the Merchant is able to access their Account balance and Transaction history through the Dashboard available within the App, having access to the following elements:</p>
                    <ul>
                      <li>Transaction history with amount, date and outcome;</li>
                      <li>amount of each transaction,</li>
                      <li>fees charged (including details of fees),  </li>
                      <li>The credit date of each transaction.</li>
                    </ul>
                  </li>
                  <li><p>In the event that the end customer is charged for a double payment (Coinbarpay and in-store payment by other means), having made the appropriate verifications, the merchant has the option of making an immediate refund against the customer directly from their dashboard. </p></li>
                </ol>
              </div></li>
              <li><div>
                <h3>DURATION AND TERMINATION</h3>
                <ol>
                  <li><p>This contract provides for an unlimited duration and starts from the date of signing.</p></li>
                  <li><p>The Parties may terminate this contract by notice to be sent by registered letter with return receipt or PEC, to their respective institutional addresses, with at least 3 months' notice.</p></li>
                  <li>
                    <h5> Client's right of withdrawal</h5>
                    <p>In the event of early termination of the contract, the Client shall pay the Supplier the amount due in respect of the Services performed and documented up to the time of termination while holding the Supplier harmless for the expenses incurred in performing the service and lost earnings.</p>
                  </li>
                  <li>
                    <h5>Faculty of withdrawal for cause of Supplier and Customer</h5>
                    <p>The Supplier and the Customer may terminate the contract without notice if a cause arises that does not permit the continuation, even temporarily, of the relationship.</p>
                  </li>
                  <li>
                    <h5>Exclusivity clause</h5>
                    <p>The Merchant agrees to the introduction of a unilateral exclusivity clause within this contract.<br />
                      The purpose of exclusivity is to provide the parties with a closer bond of cooperation.<br />
                      By accepting the exclusivity clause, the Merchant agrees not to introduce within its distribution and payments process, services competing with the Coinbarpay service, it therefore undertakes not to conclude with third parties similar contracts within the points of sale present in the Italian territory for a period of time equal to 2 years from the signing of the contract in question.<br />
                      Failure to comply with this obligation shall result in the payment of damages against Coinbar in accordance with the provisions of Art. 1223 c.c.
                    </p>
                  </li>
                </ol>
              </div></li>
              <li><div>
                <h3>VARIATIONS</h3>
                <p>Any changes in the way the service is provided must be discussed and approved by both parties in advance.</p>
              </div></li>
              <li><div>
                <h3>CORRESPECTOR</h3>
                <p>The COINBARPay service provides 2 different types of subscriptions (Starter and Pro) detailed within the tables below.
                  <br />
                  Table no. 1 reports the conditions valid in head of the two main subscription options.
                </p>
                <table>
                  <tbody>
                    <tr>
                      <td></td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Starter</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Pro</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Abbonamento</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>0</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>€4.90</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Crypto to Euro</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>1.75%</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>1.50%</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Crypto to crypto</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>0.90%</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>0.75%</span></p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>On the other hand, Table No.2 shows the conditions in terms of fees applied to the 2 types of subscriptions within the promotion offered by COINBAR with a duration of 6 months from the date of signing the contract and accepting the Terms and Conditions of Service.</p>
                <table>
                  <tbody>
                    <tr>
                      <td> </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Starter</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Pro</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Subscription</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>0</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>€0.00</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Crypto to Euro</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>1.50%</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>1.00%</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Crypto to crypto</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>0.70%</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>0.50%</span></p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  It should be noted that any payment received from the customer having a counter value of more than 15,000 euros must be accompanied by a receipt that the customer must send to the email address compliance@coinbar.io<br />
                  Coinbar, for the purpose of providing the service in full compliance with anti-money laundering regulations, reserves the right to ask the customer to view the receipts issued upon receipt of payment through Coinbarpay.<br />
                  The parties agree on the possibility of reevaluation of the agreed consideration from the expiration of the contract with validity 1 year for which automatic renewal is provided.
                </p>
                <ol>
                  <li>
                    <h5> MODE OF PAYMENT</h5>
                    <p>
                      Coinbar withholds from the Merchant's receipts made through Coinbarpay the amount arising from monthly service fee and transaction fee, leaving the Merchant's proprietary account debited with the net amount.<br />
                      At the same time Coinbar will issue an invoice on a monthly basis covering the 2 fees mentioned above, no invoice will need to be issued by the Merchant.<br />
                      As for the mode of collection for the Merchant, there is an automatic settlement on a weekly basis for the withdrawal of the funds available in the account in addition to the possibility of making a manual withdrawal directly from the merchant's dashboard at any instant as long as it is an amount greater than 100 euros.<br />
                      If no payments are collected through COINBARPay for an extended period of time and it is not possible for COINBAR to "draw down" the monthly service fee due to unavailability of funds, the merchant must reload the amount needed to settle the monthly service fee.<br />
                      If for a period of more than 30 consecutive days from the payment due date, the merchant fails to upload the necessary funds to settle what is due, COINBAR will suspend the service by sending an e-mail notice to the merchant.
                    </p>
                  </li>
                  <li><p> The Supplier is reserved the right tosuspend service in case of delinquency resulting from late or non-payment.
                    In such a case, service will be restored only and exclusively upon full payment of the amount owed by the Customer.
                  </p></li>
                </ol>
              </div></li>
              <li><div>
                <h3>RESPONSIBILITIES OF SUPPLIER AND CUSTOMER</h3>
                <p>Coinbar S.P.A. Is required to provide its services with professional diligence.
                  In the event that it fails in its obligations of professional diligence, it will be obliged to compensate the Client for any damage caused to him by its conduct.
                  The Customer acknowledges that he/she is bound towards Coinbar to the obligation of damage limitation and to conduct himself/herself in accordance with the principles of fairness and good faith provided for in the Civil Code (c.c.).
                  <br />
                  Coinbar S.P.A.'s obligation to indemnify. will decrease or be excluded, if the Client himself contributed to the occurrence of the damage or did not actively work on its containment, as far as he reasonably could.
                </p>
                <ol>
                  <li>
                    <h5>This contract excludes the Supplier's liability for damages not attributable to the Supplier.</h5>
                    <p>
                      Coinbar S.P.A. is not responsible for damages due to unforeseeable circumstances or force majeure.
                      It is also subject to the limitations of liability of the providers of the services required for trading and quotations. On the same terms in which such limitations are opposed by suppliers to Coinbar, they may be objected to by the latter to the Customer.
                    </p>
                  </li>
                  <li>
                    <h5>Exclusion of Supplier's liability for inefficiency, interruption of services, etc.</h5>
                    <p>
                      The Merchant acknowledges that the application of professional diligence does not, however, imply a commitment or guarantee of the unconditional continuity of the services provided by Coinbar, for influential aspects arising from the Markets, trading and/or price dissemination systems, as well as intermediaries that Coinbar may potentially use.
                      <br />
                      Coinbar S.P.A. is not responsible in case of interruptions or malfunctions of the offered service caused directly or indirectly by technical problems of the exchange.
                    </p>
                  </li>
                  <li><p>This contract provides for the indemnification of the Customer against the Supplier for third-party claims arising from the use of the service;</p></li>
                  <li><p> This contract excludes the Supplier's liability for loss of data, violation of the rights of third parties, delays, malfunctions, total or partial interruptions;</p></li>
                </ol>
              </div></li>
              <li><div>
                <h3> CONFIDENTIALITY OBLIGATIONS</h3>
                <p>
                  Both parties warrant that the personnel assigned to the performance of this contract will maintain, with respect to any unauthorized persons and third parties, secrecy with respect to the confidential information they will obtain in the performance of the planned activities.
                  Both parties agree to take necessary measures to prevent any disclosure of secret information to unauthorized parties, and vice versa.
                  Excluded from this obligation is information that is already known to the parties or is in the public domain or is transmitted by a third party with rights and no obligation of confidentiality. Any breaches of confidentiality obligations for which one party is responsible will entitle the other party to recover any damages incurred.

                </p>
              </div></li>
              <li><div>
                <h3> PERSONAL DATA PROCESSING</h3>
                <p>The Parties declare that they are informed about the use of their personal data and authorize their processing on computer and/or paper media, in order to fulfill all legal obligations and in any case functional to the stipulation and execution of the relationship established with this contract, in the manner and to the extent necessary to pursue these purposes, also in case of communication to third parties, where required for the execution of the contract itself or by virtue of regulatory provisions, in compliance with Regulation (EU) 679/2016 (hereinafter GDPR) and D. Lgs. June 30, 2003, no.196 as last amended by D. Lgs. 101/2018.</p>
              </div></li>
              <li><div>
                <h3> OBLIGATIONS OF SUPPLIER AND CUSTOMER</h3>
                <ol>
                  <li>The Supplier is obliged to make known to the customer all the technical and essential characteristics of what is the subject of the service provided.</li>
                  <li>The Supplier is obliged to meet the deadlines for the start of operation of the service agreed upon between the parties and to provide adequate support.</li>
                  <li> At certain times COINBARPay POS may need to perform scheduled maintenance, in such cases, the Provider agrees to notify with due anticipation the temporary unavailability of the service.
                    In the event of unscheduled maintenance work (for example, a technical failure or system error) Coinbar will make every effort to restore Merchant service as quickly as possible.
                  </li>
                  <li>The Supplier agrees to provide instant assistance to merchants from the time of signing the contract:<br />
                    -Support includes one-hour training provided by the Coinbar team aimed at showing how the service works and explaining relevant case studies;<br />
                    - assistance for malfunctions or technical problems through the automated chat service on the coinbar.io website or via phone support at 388-9008239.
                  </li>
                  <li>It is the responsibility of the Supplier to enforce confidentiality obligations on third parties involved.<br />
                    The Merchant is obliged to pay the consideration established with the contract in the agreed time and manner as well as is obliged to communicate to the supplier all information necessary for the resolution of any problems.
                  </li>
                </ol>
                <p>The Merchant and Supplier agree to handle any disputes with the end customer promptly and diligently.
                  <br />
                  Merchants agree, at their own expense, to place inside their physical stores the sticker provided via email from COINBAR in order to make the availability of the service known and highly visible.
                </p>
              </div></li>
              <li><div>
                <h3>SUSPENSION OF SERVICE</h3>
                <p>
                  As part of the due diligence required in accordance with AML laws, COINBAR may not activate the Services or deny, suspend, or restrict access to and/or use of the Services if: (i) it is not possible to obtain and verify the information and documents necessary to conduct the checks; (ii) it believes that the results of the checks are not satisfactory under the obligations imposed on COINBAR by law; (iii) the provision of the Services to the Merchant may harm COINBAR's business and reputation and/or expose the Provider to any danger or risk of loss or liability to it. The Merchant may have only one Merchant Account under this Agreement.<br />
                  COINBAR may suspend the Merchant's use of the Services if it has reasonable doubts about the security of the Merchant's Account or suspects that the Services are being used in a fraudulent or unauthorized manner, or that the Merchant has committed a material or repeated breach of its contractual obligations.<br />
                  The Merchant will be informed of such suspension and the reasons behind it as soon as practicable, unless it would reasonably compromise security measures or be contrary to law.<br />
                  COINBAR may lift such suspension and, if appropriate, issue new security codes free of charge and as soon as possible if the reasons that led to the suspension of the Services have ceased to exist.
                </p>
                <p>Under certain circumstances COINBAR may refuse to allow the Merchant to execute a Transaction. This may occur in cases where:</p>
                <ul>
                  <li> has reasonable doubts about the security of the Merchant Account or suspects that the Services have been used by a Merchant in a fraudulent or unauthorized manner;
                  </li>
                  <li> Has reasonable grounds to believe that a Merchant is acting in violation of this Agreement; or </li>
                  <li>In the presence of a legal obligation.</li>
                </ul>

              </div></li>
              <li><div>
                <h3>COMMUNICATIONS</h3>
                <p>
                  The Parties, for any communication they should exchange regarding this contract and its execution, shall refer to the following addresses:<br />
                  PEC COINBAR SPA  address : _____________ <br />
                  Address of Legal Representative Antonello Cugusi: antonello.cugusi@coinbar.io<br />

                  Address for Technical Assistance: support@coinbar.io<br />
                  Address for Legal and Compliance Assistance: compliance@coinbar.io<br />

                  In case of communications from Coinbar to the merchant, the company will resort to the email address used by the merchant during registration at coinbar.io

                </p>
                <p>In the event of disputes concerning the receipt of ordinary e-mail messages, the parties mutually undertake to facilitate in good faith the performance of any verification and feedback activities and agree, in particular: </p>
                <ul>
                  <li>To keep intact the computing devices used for transmitting and/or receiving e-mail messages;
                  </li>
                  <li>to promptly request, where necessary, from the respective e-mail service providers news useful for verification and feedback activities.
                  </li>
                </ul>
              </div></li>
              <li><div>
                <h3>EXCLUSIVE FORUM IN CASE OF DISPUTE</h3>
                <p>Any dispute relating and/or connected to this contract, including those concerning its validity, interpretation, execution or termination, shall be referred to the exclusive jurisdiction of the Supplier's Court (Rome), with the express exclusion of any other possibly concurrent judicial authority.</p>
              </div></li>
            </ol>
            <p>For acceptance
              <br />
              COINBAR S.p.a.<br />
              The Trader
            </p>
          </section>
          :

          <section class="content">
            <h1>ACCORDO RELATIVO AI TERMINI DI SERVIZIO DI COINBARPAY POS</h1>
            <ol>
              <li><div>
                <h3>PREMESSE</h3>
                <p>
                  I presenti termini di utilizzo costituiscono il contratto (“Contratto”) tra il commerciante (“Commerciante”) che decida di adottare il servizio COINBARPay POS e COINBAR S.p.a. (“COINBAR”) (collettivamente, le “Parti”).
                  <br />
                  Salvo disposizione contraria, qualsiasi riferimento al Contratto costituisce riferimento anche a qualsiasi addendum applicabile, quali le condizioni generali, privacy policy e cookies policy.
                </p>
                <p>
                  COINBAR SPA, Codice Fiscale/P. IVA 14939301009 è una società con sede legale in Via della Marcigliana n.532, Roma, rappresentata legalmente dal dott. Cugusi Antonello, di seguito denominata il “Fornitore” ed è iscritta presso la sezione speciale del registro degli operatori di Valute Virtuali (VASP) tenuto dall’Organismo Agenti e Mediatori, con  n. di iscrizione PSV16.
                </p>
                <p>
                  Il servizio COINBARPay POS è riservato esclusivamente alle persone fisiche e giuridiche e ai loro intermediari che agiscono nell’ambito della loro attività commerciale, imprenditoriale, artigianale o professionale e che sono stabiliti o hanno una filiale in uno dei paesi facenti parte dello Spazio economico europeo (“SEE”).
                </p>
                <p>
                  Gli obblighi oggetto del presente Contratto sono da considerarsi validi e applicabili, ove possibile, per ciascun utente che viene designato da un Commerciante a utilizzare i servizi di COINBARPay come indicati di seguito.
                </p>
                <p>
                  Qualora il Commerciante necessiti di ulteriori informazioni su COINBARPay e/o sui servizi offerti da COINBAR S.p.a. può fare riferimento al nostro sito coinbar.io o in alternativa inviare una mail ai seguenti indirizzi:<br />

                </p>
                <ul>
                  <li>info@coinbar.io</li>
                  <li>business@coinbar.io</li>
                </ul>
              </div></li>
              <li><div>
                <h3> SERVIZIO OGGETTO DEL CONTRATTO</h3>
                <p>Il servizio si svolgerà esclusivamente attraverso la modalità online.<br />
                  La società Coinbar offre:</p>
                <ul>
                  <li><div>
                    <p>Il serivzio COINBAR-PAY: permette a clienti di tipo merchant/business di poter gestire pagamenti digitali con cryptovalute e cambio in valuta fiat (euro);</p>
                  </div></li>
                </ul>
                <h5>COINBAR-PAY</h5>
                <p>
                  La possibilità di accettare pagamenti in criptovaluta e convertirli in valuta FIAT è resa possibile attraverso un processo chiamato "crypto-to-fiat conversione" integrato all’interno della piattaforma Coinbar Pay.<br />
                  Questa funzione è particolarmente utile per i commercianti che non vogliono trattenere
                  criptovaluta e preferiscono avere i propri fondi in valuta fiat. Aiuta anche i
                  commercianti ad evitare la volatilità del mercato delle criptovalute. Inoltre, consente ai
                  commercianti di contabilizzare facilmente le loro entrate nella loro valuta locale e di evitare
                  eventuali commissioni di conversione quando utilizzano i loro fondi.
                </p>
                <p>
                  Il servizio verrà erogato con una webapp customizzata Coinbar Pay integrata via API all'e-commerce e attraverso App Android dedicata all’utilizzo come POS in store.
                </p>
                <p>Il cliente del merchant viene guidato nel pagamento crypto, potendo utilizzare due metodi:</p>
                <ol>
                  <li>wallet privato,</li>
                  <li>account coinbar.</li>
                </ol>
                <p>
                  In caso di pagamento tramite wallet esterno, saranno disponibili le seguenti criptovalute:
                </p>
                <ul>
                  <li>BTC</li>
                  <li>USDT</li>
                  <li>XDB</li>
                  <li>XRP</li>
                </ul>
                <p>
                  Ulteriori criptovalute saranno rese disponibili da Coinbar nel corso dei successivi aggiornamenti.<br />
                  Utilizzando il metodo di pagamento con account interno a Coinbar sono invece utilizzabili tutte le criptovalute attualmente presenti in piattaforma.
                </p>
                <p>
                  I  clienti del Commerciante potranno effettuare i pagamenti sia ricorrendo ad un wallet esterno a loro intestato sia registrandosi sulla piattaforma Coinbar.
                </p>
              </div></li>
              <li><div>
                <h3>MODALITA’ DI SVOLGIMENTO DEL SERVIZIO COINBARPAY</h3>
                <p>
                  In tale sede si riporta brevemente il flusso secondo il quale si sviluppa il servizio, per quanto riguarda ulteriori dettagli tecnici il commerciante potrà richiedere all’indirizzo mail business@coinbar.io di consultare l’allegato tecnico CoinbarPay Integration Preview.pdf
                </p>
                <p>Svolgimento:</p>
                <ol>
                  <li>
                    <h5>Procedura di creazione account</h5>
                    <ul>
                      <li>la società accettante pagamenti tramite COINBARPAY procede alla creazione di un proprio account presso https://coinbar.io/ al fine di attivare il servizio;</li>
                      <li>segue la procedura di verifica KYC secondo il protocollo interno adottato da Coinbar;</li>
                      <li>contestualmente la società cliente attiva il servizio di exchange approvando i termini e condizioni della piattaforma consultabili al seguente link https://coinbar.io/terms-account;</li>
                    </ul>
                  </li>
                  <p>TERMINATA LA FASE DI PREDISPOSIZIONE ED APPROVAZIONE DEGLI ACCOUNT NECESSARI, IL SERVIZIO DIVIENE OPERATIVO E SI SVILUPPA COME DI SEGUITO:</p>
                  <li>
                    <h5>Esecuzione servizio e gestione del pagamento:</h5>
                    <ul>
                      <li>
                        Il pagamento avviene attraverso una richiesta web da parte della “piattaforma merchant” verso Coinbar Pay, con input i dati dell’utente, del prodotto in acquisto, del costo in euro, e altri dati custom.
                      </li>
                      <li>
                        Apertura della pagina Coinbar che visualizza il riepilogo della richiesta di pagamento e la scelta di una delle due modalità gestite.
                      </li>
                      <li>L’utente sceglie la modalità di pagamento tra wallet privato e conto Coinbar.</li>
                    </ul>
                    <p>La gestione del pagamento è descritta all’interno del punto 4.4 ss dell’allegato tecnico facente parte integrante del contratto, che può essere richiesto via mail scrivendo all’indirizzo:<br /> compliance@coinbar.io</p>
                  </li>
                  <li>
                    <h5>Business Dashboard</h5>
                    <p>Attraverso l’applicazione COINBARPay, il Commerciante è in grado di accedere al saldo del proprio Conto e alla cronologia delle proprie Operazioni attraverso la Dashboard disponibile all’interno dell’App, avendo accesso ai seguenti elementi:</p>
                    <ul>
                      <li>storico delle transazioni con importo, data ed esito;</li>
                      <li>importo di ciascuna operazione,</li>
                      <li>commissioni addebitate (compreso il dettaglio delle commissioni), </li>
                      <li>la data di accredito di ciascuna operazione.</li>
                    </ul>
                  </li>
                  <li><p>Nell’ipotesi nella quale al cliente finale fosse addebitato un doppio pagamento (Coinbarpay e pagamento in negozio con altre modalità), fatte le opportune verifiche, il commerciante ha la possibilità di effettuare un’immediata operazione di refund nei confronti del cliente direttamente dalla propria dashboard.</p></li>
                </ol>
              </div></li>
              <li><div>
                <h3>DURATA E RECESSO</h3>
                <ol>
                  <li><p> Il presente contratto prevede una durata illimitata e decorre dalla data di sottoscrizione.</p></li>
                  <li><p>Le Parti possono recedere dal presente contratto mediante comunicazione da trasmettere con lettera raccomandata con avviso di ricevimento o PEC, ai rispettivi indirizzi istituzionali, con preavviso di almeno 3 mesi.</p></li>
                  <li>
                    <h5>Facoltà di recesso del Cliente</h5>
                    <p>In caso di risoluzione anticipata del contratto, il Cliente corrisponderà al Fornitore l’importo dovuto in relazione ai Servizi svolti e documentabili fino al momento del recesso tenendolo indenne dalle spese sostenute per eseguire la prestazione del servizio e dal mancato guadagno.</p>
                  </li>
                  <li>
                    <h5>Facoltà di recesso per giusta causa di Fornitore e Cliente</h5>
                    <p>Il Fornitore ed il Cliente possono recedere dal contratto senza preavviso qualora si verifichi una causa che non consenta la prosecuzione, anche provvisoria, del rapporto.</p>
                  </li>
                  <li>
                    <h5> Clausola di esclusiva</h5>
                    <p>Il Commerciante accetta l’introduzione di una clausola di esclusiva unilaterale all’interno del presente contratto.<br />
                      Scopo dell'esclusiva è quello di garantire alle parti un più stretto vincolo di collaborazione.<br />
                      Accettando la clausola di esclusiva il Commerciante si impegna a non introdurre all’interno del proprio processo di distribuzione e pagamenti, servizi concorrenti al servizio Coinbarpay, si impegna pertanto a non concludere con soggetti terzi contratti analoghi all'interno dei punti vendita presenti sul territorio italiano per un periodo di tempo pari a 2 anni a decorrere dalla sottoscrizione del contratto in oggetto.
                      Il mancato rispetto di tale obbligo comporta il pagamento del risarcimento del danno nei confronti di Coinbar ai sensi di quanto disposto dall’art. 1223 c.c.
                    </p>
                  </li>
                </ol>
              </div></li>
              <li><div>
                <h3>VARIAZIONI </h3>
                <p>Eventuali modifiche alle modalità di fornitura del servizio dovranno essere preventivamente discusse ed approvate da entrambe le parti.</p>
              </div></li>
              <li><div>
                <h3>CORRISPETTIVO</h3>
                <p>Il servizio COINBARPay prevede 2 diverse tipologie di abbonamento (Starter e Pro) dettagliatamente descritte all’interno delle tabelle di seguito riportate.
                  <br />
                  La tabella n. 1 riporta le condizioni valide in capo alle due principali opzioni di abbonamento.
                </p>
                <table>
                  <tbody>
                    <tr>
                      <td></td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Starter</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Pro</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Abbonamento</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>0</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>€4.90</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Crypto to Euro</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>1.75%</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>1.50%</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Crypto to crypto</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>0.90%</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>0.75%</span></p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>La tabella n.2 riporta invece le condizioni in termini di commissioni applicate alle 2 tipologie di abbonamento all’interno della promozione offerta da COINBAR con durata di 6 mesi a decorrere dalla data di sottoscrizione del contratto ed accettazione dei Termini e condizioni del servizio.</p>
                <table>
                  <tbody>
                    <tr>
                      <td> </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Starter</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Pro</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Abbonamento</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>0</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>€0.00</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Crypto to Euro</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>1.50%</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>1.00%</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>Crypto to crypto</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>0.70%</span></p>
                      </td>
                      <td>
                        <p><span style={{ fontWeight: 400 }}>0.50%</span></p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>Si precisa che ogni pagamento ricevuto dal cliente avente controvalore superiore a 15.000 euro dovrà essere accompagnato da un giustificativo che il cliente stesso dovrà inviare all’indirizzo mail compliance@coinbar.io</p>
                <p>Coinbar, ai fini dell’erogazione del servizio nel pieno rispetto della normativa in materia di antiriciclaggio, si riserva la facoltà di chiedere al cliente la visione degli scontrini emessi alla ricezione del pagamento tramite Coinbarpay.</p>
                <p>Le parti concordano la possibilità di rivalutare il corrispettivo pattuito a partire dallo scadere del contratto con validità 1 anno per il quale è previsto il rinnovo automatico.</p>
                <ol>
                  <li>
                    <h5>MODALITA’ DI PAGAMENTO</h5>
                    <p>Coinbar trattiene dagli incassi del Merchant avvenuti tramite Coinbarpay l’importo derivante da service fee mensile e da transaction fee lasciando addebitato sull'account di titolarità del Commerciante l’importo netto.
                      Contestualmente Coinbar rilascerà una fattura con cadenza mensile avente ad oggetto le 2 fee sopra-citate, non sarà necessaria l’emissione di alcuna fattura da parte del Commerciante.
                    </p>
                    <p>Quanto alla modalità di incasso per il Commerciante è  previsto un settlement automatico con cadenza settimanale per il prelievo dei fondi disponibili sull’account oltre alla possibilità di eseguire un prelievo manuale direttamente dalla dashboard del merchant  in qualsiasi istante a patto che si tratti di un importo superiore ai 100 euro.
                      <br />Qualora per un periodo di tempo prolungato non vengano incassati pagamenti attraverso COINBARPay e non sia possibile per COINBAR "prelevare" la service fee mensile per mancata disponibilità di fondi, il commerciante dovrà ricaricare l’importo necessario a saldare la service fee mensile.
                      Se per un periodo superiore ai 30 giorni consecutivi dalla data di scadenza del pagamento, il commerciante non provveda a caricare i fondi necessari a saldare quanto dovuto, COINBAR provvederà a sospendere il servizio inviando una mail di comunicazione al commerciante.
                    </p>
                  </li>
                  <li><p>Al Fornitore viene riservata la facoltà di sospendere il servizio in caso di morosità derivante da un ritardo o da un mancato pagamento.
                    In tal caso, il ripristino del servizio avverrà solo ed esclusivamente a seguito dell’integrale pagamento di quanto dovuto dal Cliente.
                  </p></li>
                </ol>
              </div></li>
              <li><div>
                <h3>RESPONSABILITA DI FORNITORE E CLIENTE</h3>
                <p>Coinbar S.P.A. è tenuta a fornire i propri servizi con diligenza professionale.
                  Nel caso in cui essa venga meno agli obblighi di diligenza professionale, sarà tenuta a risarcire il Cliente del danno eventualmente arrecatogli con la sua condotta.
                  Il Cliente prende atto di essere tenuto nei confronti di Coinbar all’obbligo del contenimento del danno e ad un comportamento conforme ai principi di correttezza e buona fede previsti dal Codice Civile (c.c.).
                  <br />
                  L’obbligo al risarcimento da parte di Coinbar S.P.A. diminuirà o verrà escluso, qualora il Cliente stesso abbia contribuito all’insorgere del danno o non abbia attivamente operato al suo contenimento, per quanto gli fosse ragionevolmente possibile.
                </p>
                <ol>
                  <li>
                    <h5>Il contratto in oggetto esclude la responsabilità del Fornitore per danni ad esso non imputabili.</h5>
                    <p>Coinbar S.P.A. non è responsabile per i danni dovuti a caso fortuito o a cause di forza maggiore.
                      Inoltre, è sottoposta alle limitazioni di responsabilità dei fornitori dei servizi necessari per il trading e le quotazioni. Negli stessi termini in cui tali limitazioni vengano opposte dai fornitori a Coinbar, potranno essere da quest’ultima eccepite al Cliente.
                    </p>
                  </li>
                  <li>
                    <h5> Esclusione della responsabilità del Fornitore per disservizi, interruzione di servizi, ecc.</h5>
                    <p>Il Commerciante prende atto che l’applicazione della diligenza professionale non comporta tuttavia l’impegno o la garanzia della continuità incondizionata dei servizi forniti da Coinbar, per gli aspetti influenti derivanti dai Mercati, dai sistemi di contrattazione e/o di diffusione dei prezzi, nonché dagli intermediari di cui Coinbar può potenzialmente servirsi.
                      <br />
                      Coinbar S.P.A. non è responsabile in caso di interruzioni o malfunzionamenti del servizio offerto causati direttamente o indirettamente da problemi tecnici dell’exchange.
                    </p>
                  </li>
                  <li><p> Il contratto in oggetto prevede la manleva del Cliente nei confronti del Fornitore per pretese di terzi derivanti dall’utilizzo del servizio;</p></li>
                  <li><p> Il contratto in oggetto esclude la responsabilità del Fornitore per perdite di dati, violazioni dei diritti di terzi, ritardi, malfunzionamenti, interruzioni totali o parziali;</p></li>
                </ol>
              </div></li>
              <li><div>
                <h3>OBBLIGHI DI RISERVATEZZA</h3>
                <p>Entrambe le parti si rendono garanti che il personale destinato all’esecuzione del presente contratto mantenga, nei confronti di qualsiasi persona non autorizzata e di terzi, il segreto per quanto riguarda le informazioni confidenziali che otterrà per lo svolgimento delle attività previste.
                  <br />Entrambe le parti si impegnano ad adottare le misure necessarie ad evitare ogni divulgazione di informazioni segrete a soggetti non autorizzati, e viceversa.<br />
                  Sono escluse da tale obbligo quelle informazioni che siano già note alle parti o siano di pubblico dominio o siano trasmesse da terzi aventi diritto e senza obbligo di riservatezza. Eventuali violazioni degli obblighi di riservatezza di cui una parte sia responsabile daranno diritto all’altra parte di ottenere l’eventuale risarcimento dei danni subiti.
                </p>
              </div></li>
              <li><div>
                <h3>TRATTAMENTO DATI PERSONALI</h3>
                <p>Le Parti dichiarano di essere informate in merito all'utilizzo dei propri dati personali e ne autorizzano il trattamento su supporti informatici e/o cartacei, al fine di adempiere a tutti gli obblighi di legge e comunque funzionali alla stipulazione e all’esecuzione del rapporto instaurato con il presente contratto, nei modi e nei limiti necessari per perseguire tali finalità, anche in caso di comunicazione a terzi, laddove previsto per l'esecuzione del contratto stesso o in virtù di disposizioni normative, nel rispetto del Regolamento (UE) 679/2016 (di seguito GDPR) e del D. Lgs. 30 giugno 2003, n.196 così come da ultimo modificato con il D. Lgs. 101/2018.</p>
              </div></li>
              <li><div>
                <h3>OBBLIGHI DI FORNITORE E CLIENTE</h3>
                <ol>
                  <li>Il Fornitore ha l’obbligo di rendere note al cliente tutte le caratteristiche tecniche ed essenziali di quanto oggetto del servizio fornito.</li>
                  <li>Il Fornitore è tenuto a rispettare le scadenze di inizio operatività del servizio pattuite tra le parti e a fornire un’adeguata assistenza.</li>
                  <li>In alcuni momenti COINBARPay POS può avere bisogno di effettuare manutenzione programmata, in tali ipotesi, il Fornitore si impegna a comunicare con dovuta anticipazione la temporanea indisponibilità del servizio.
                    <br />In caso di interventi di manutenzione non programmati (per esempio, un guasto tecnico o un errore del sistema) Coinbar farà tutto il possibile per ripristinare il servizio del Commerciante nel minor tempo possibile.
                  </li>
                  <li>Il Fornitore si impegna a fornire ai commercianti un’assistenza istantanea a partire dal momento di sottoscrizione del contratto:<br />
                    -l’assistenza prevede un’ora di formazione erogata dal team Coinbar volta a mostrare il funzionamento del servizio e a spiegare le casistiche più rilevanti;<br />
                    - assistenza per malfunzionamenti o problemi tecnici grazie al servizio di chat automatica presente sul sito coinbar.io o tramite assistenza telefonica al numero 388-9008239.
                  </li>
                  <li>Grava in capo al Fornitore il compito di far rispettare gli obblighi di riservatezza a soggetti terzi coinvolti.<br />
                    Il Commerciante è tenuto a pagare il corrispettivo stabilito con il contratto nei tempi e nei modi pattuiti così come è tenuto a comunicare al fornitore tutte le informazioni necessarie alla risoluzione di eventuali problemi.
                  </li>
                </ol>
                <p>Il Commerciante ed il Fornitore si impegnano a gestire con tempestività e diligenza le eventuali contestazioni con il cliente finale.
                  <br />
                  I Commercianti si impegnano, a proprie spese, a collocare all’interno dei loro negozi fisici l’adesivo fornito via mail da COINBAR al fine di rendere nota e ben visibile la disponibilità del servizio.
                </p>
              </div></li>
              <li><div>
                <h3> SOSPENSIONE DEL SERVIZIO</h3>
                <p>Nell’ambito della due diligence richiesta in conformità alle leggi antiriciclaggio, COINBAR potrebbe non attivare i Servizi o negare, sospendere o limitare l’accesso e/o l’utilizzo dei Servizi qualora: (i) non sia possibile ottenere e verificare le informazioni e i documenti necessari per effettuare i controlli; (ii) ritenga che gli esiti dei controlli non siano soddisfacenti in base agli obblighi imposti a COINBAR dalla legge; (iii) la fornitura dei Servizi al Commerciante possa pregiudicare il business e la reputazione di COINBAR e/o esporre il Fornitore a qualsiasi pericolo o rischio di perdite o responsabilità a suo carico. Il Commerciante può avere un solo Conto del Commerciante ai sensi del presente Contratto.</p>
                <p>COINBAR può sospendere l’utilizzo dei Servizi da parte del Commerciante  qualora abbia ragionevoli dubbi circa la sicurezza del Conto del Commerciante o sospetti che i Servizi siano utilizzati in modo fraudolento o non autorizzato, o che il Commerciante abbia commesso una violazione sostanziale o reiterata dei propri obblighi contrattuali. </p>
                <p>Il Commerciante verrà informato di tale sospensione e delle ragioni ad essa sottese non appena possibile, salvo che ciò possa ragionevolmente compromettere le misure di sicurezza o risulti contrario alla legge. </p>
                <p>COINBAR può revocare tale sospensione e, se del caso, rilasciare gratuitamente e non appena possibili nuovi codici di sicurezza, qualora siano cessate le ragioni che hanno portato alla sospensione dei Servizi.  </p>
                <p>In determinate circostanze COINBAR può rifiutare al Commerciante di eseguire un’Operazione. Ciò può avvenire nei casi in cui:</p>
                <ul>
                  <li> ha ragionevoli dubbi circa la sicurezza del Conto del Commerciante o sospetti che i Servizi siano stati utilizzati da un Commerciante in modo fraudolento o non autorizzato;
                  </li>
                  <li>ha ragionevoli motivi per ritenere che un Commerciante stia agendo in violazione del presente Contratto; </li>
                  <li>in presenza di un obbligo di legge.</li>
                </ul>

              </div></li>
              <li><div>
                <h3>COMUNICAZIONI</h3>
                <p>Le Parti, per ogni comunicazione che dovranno scambiarsi in merito al presente contratto e alla sua esecuzione, faranno riferimento ai seguenti indirizzi:<br />
                  Indirizzo PEC COINBAR SPA:  _____________<br />
                  Indirizzo del Legale Rappresentante Antonello Cugusi: antonello.cugusi@coinbar.io<br />
                  <br />
                  Indirizzo per Assistenza tecnica: support@coinbar.io<br />
                  Indirizzo per Assistenza Legal e Compliance: compliance@coinbar.io<br />
                  In caso di comunicazioni da parte di Coinbar verso il commerciante, la società ricorrerà all’indirizzo mail usato dal commerciante nel corso della registrazione presso coinbar.io
                </p>
                <p> Nel caso di contestazioni riguardanti la ricezione di messaggi di posta elettronica ordinaria, le parti si impegnano reciprocamente ad agevolare secondo buona fede il compimento di eventuali attività di verifica e riscontro e si impegnano, in particolare: </p>
                <ul>
                  <li>a conservare integri i dispositivi informatici utilizzati per la trasmissione e/o la ricezione dei messaggi di posta elettronica;
                  </li>
                  <li>a richiedere tempestivamente, ove necessario, ai rispettivi provider dei servizi di posta elettronica notizie utili per le attività di verifica e riscontro.
                  </li>
                </ul>
              </div></li>
              <li><div>
                <h3>FORO ESCLUSIVO IN CASO DI CONTROVERSIA</h3>
                <p>Qualunque controversia relativa e/o connessa al presente contratto, ivi comprese quelle concernenti la validità, interpretazione, esecuzione o risoluzione del medesimo, sarà rimessa alla competenza esclusiva del Foro del Fornitore (Roma), con espressa esclusione di ogni altra autorità giudiziaria eventualmente concorrente.</p>
              </div></li>
            </ol>
            <p>Per accettazione<br />
              COINBAR S.p.a.<br />
              Il Commerciante
            </p>
          </section>
      }
    </div>
  );
}

export default TOSCBPay;
