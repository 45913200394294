import React from "react";
import { Routes, Route, UNSAFE_NavigationContext, } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { useNavigate, useLocation } from "react-router-dom";


import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";

//icone
import facebookIcon from './assets/facebook-b.png'
import twitterIcon from './assets/twitter-b.png'
import instagramIcon from './assets/instagram-b.png'
import telegramIcon from './assets/telegram.png'
import linkedinIcon from './assets/linkedin.png'

import closeB from './assets/closeB.png'
//stili
import "./styles/global.scss"
import RoutesM from './Routes'
import CookieConsent from "react-cookie-consent";
import _links from "./assets/jsons/links.json"
const axios = require('axios').default;

function App() {
  let navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  const [scrolled, setScrolled] = React.useState(false);
  const [isChangedLang, setChangedLang] = React.useState(false); //
  const [isEng, setEng] = React.useState();
  const [isPop, setPop] = React.useState(false);
  const [popOpen, setPopOpen] = React.useState(false);
  const [hidePop, SetHidePop] = React.useState(false)
  const [links, setLinks] = React.useState(_links)
  const [message, setMessage] = React.useState({
    id: "634eb65fe4078869a1a1e599",
    title: "BTC è ora attivo su Coinbar Pay",
    subtitle: "Inizia ad accettare BTC come pagamento dai tuoi clienti",
    text: "Dalle ore 10.00 di oggi 11/11/22 Bitcoin è entrato nella lista di token compatibili con Coinbar Pay.",
    active: false,
    recurring: false,
    icon_path: "",
    image_path: "https://quifinanza.it/wp-content/uploads/sites/5/2021/08/bitcoin.jpg",
    creation_time: "2022-10-16T22:00:00.000Z"
  });
  let location = useLocation();
  React.useEffect(() => {
    const fetchLinksData = async () => {
      try {
        // It's good practice to use 'new URL()' for constructing URLs
        // This ensures that the URL is correctly formed and can help avoid simple mistakes
        const url = '/jsons/links.json'
        console.log(url,window.location,window.location.href)
        const response = await fetch(window.location.origin+url);
        if (!response.ok) {
          // Throwing an error if response from fetch is not 'ok'
          // This will direct us to the 'catch' block to handle errors
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
  
        // Setting the state with the fetched data
        setLinks(data);
        console.log(data)
      } catch (error) {
        // It's a good practice to handle errors in catch block
        // Here, you could set an error state and use it to show an error message to the user, if needed
        console.error("Failed to fetch data: ", error);
      }
    };
    fetchLinksData();
  }, []);
  React.useEffect(() => {

    let el = document.getElementById("side-menu")
    el.classList.remove("active")
    getMessage()
    if (!isChangedLang) {
      if (window.navigator.language.includes("it")) {
        setEng(false)
      } else {
        setEng(true)
      }
    }
    window.addEventListener("scroll", (e) => {

      if (!scrolled && window.pageYOffset > 10) {
        setScrolled(true);
      }
      if (window.pageYOffset === 0) {
        setScrolled(false);
      }
    });

    return () => { // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", (e) => {
        console.log(e)
      });
    };

  }, [location]);


  const closeMenu = () => {
    let el = document.getElementById("side-menu")
    el.classList.toggle("active")
  }
  const goToPage = (arg) => {
    navigate("../" + arg, { replace: true })
  }
  const openPopup = () => {
    let el = document.getElementById("popup")
    el.classList.add("active")
    setPopOpen(true)
    setPop(true)

    SetHidePop(false)

  }
  const closePopup = () => {
    let el = document.getElementById("popup")
    el.classList.remove("active")
    setPopOpen(false)
    setPop(false)

    SetHidePop(true)

  }
  const getMessage = (arg) => {
    if (arg) {
      axios({
        method: 'get',
        url: arg ? 'https://api.coinbar.io/coinbar/api/v2/web/announcements?lang=en' : 'https://api.coinbar.io/coinbar/api/v2/web/announcements?lang=it',
        responseType: 'stream'
      })
        .then(function (response) {
          console.log(response.data)
          if (response.data !== "{}") {
            setPop(true)
            setPopOpen(true)
            SetHidePop(false)
            setMessage(JSON.parse(response.data))

          }
        })
        .catch((e) => {
          console.log("ERRORE ANNUNCIO", e)
        })
    } else {
      axios({
        method: 'get',
        url: isEng ? 'https://api.coinbar.io/coinbar/api/v2/web/announcements?lang=en' : 'https://api.coinbar.io/coinbar/api/v2/web/announcements?lang=it',
        responseType: 'stream'
      })
        .then(function (response) {
          if (response.data !== "{}") {
            setPop(true)
            setPopOpen(true)
            SetHidePop(false)
            setMessage(JSON.parse(response.data))

          }
        })
        .catch((e) => {
          console.log("ERRORE ANNUNCIO", e)
        })
    }
  }
  return (
    <div >
      <div id="side-menu">
        <div id="close-menu" onClick={closeMenu}>
          <div />
          <div />
        </div>
        <nav >
          <div className="side-link"><a onClick={() => goToPage("")}>Home</a></div>
          <div className="side-link"><a onClick={() => goToPage("exchange")}>Exchange</a></div>
          <div className="side-link secondary"><a href="https://trade.coinbar.io/login">Dashboard</a></div>
          <div className="side-link secondary"><a onClick={() => goToPage("/commissioni")}>{isEng ? "Commissions" : "Commissioni"}</a></div>
          <div className="side-link"><a onClick={() => goToPage("bar")}>{isEng ? "The Bar" : "Il locale"}</a></div>
          <div className="side-link secondary"><a onClick={() => goToPage("/bar/prenota")}>{isEng ? "Book a table" : "Prenota un tavolo"}</a></div>
          <div className="side-link secondary"><a onClick={() => goToPage("/bar/menu")}>Menù</a></div>
          <div className="side-link secondary"><a onClick={() => goToPage("/bar/evento")}>{isEng ? "Organize an event" : "Organizza un evento"}</a></div>
          <div className="side-link"><a onClick={() => goToPage("b2b")}>B2B</a></div>
          <div className="side-link secondary"><a href={links.cbpay} >Coinbar Pay</a></div>
          <div className="side-link secondary"><a onClick={() => goToPage("/b2b/otc")}>{isEng ? "Business OTC" : "Business OTC"}</a></div>

          <div className="side-link"><a onClick={() => goToPage("lavora-con-noi")}>{isEng ? "Work with us" : "Lavora con noi"}</a></div>
          <div className="side-link"><a onClick={() => goToPage("contatti")}>{isEng ? "Contact" : "Contattaci"}</a></div>
          <div className="side-link"><a  href={links.documentazione} >{isEng ? "Documentation" : "Documentazione"}</a></div>
          <div className="divider" />
          <div className="social-links">
            <a className="social-link" href={links.twitter}><img src={twitterIcon} /></a>
            <a className="social-link" href={links.instagram}><img src={instagramIcon} /></a>
            <a className="social-link" href={links.linkedin}><img src={linkedinIcon} /></a>
            <a className="social-link" href={links.telegram}><img src={telegramIcon} /></a>
            <a className="social-link" href={links.facebook}><img src={facebookIcon} /></a>
          </div>


        </nav>
      </div>
      {
        !hidePop && message.active &&
        <div id="modal-back" >
        </div>
      }
      {
        isPop && message.active &&
        !hidePop &&
        <div id="popup-clicker" className="icon" onClick={closePopup}>
          <img src={closeB} alt="icona di annuncio in arrivo" />
        </div>
      }
      {
        isPop && message.active && !hidePop &&
        <div id="popup" className="pop-up-wrapper active" >
          <div className="content">
            {message.image_path && <img src={message.image_path} alt={message.title} />}
            <h4>{message.title}</h4>
            <span>{message.subtitle}</span>
            <p>{message.text}</p>
            <button onClick={closePopup}>{isEng?"CLOSE":"CHIUDI"}</button>
          </div>

        </div>
      }
      <Header active={scrolled} isTabletOrMobile={isTabletOrMobile} isEng={isEng} setEng={setEng} getMessage={getMessage} isChanged={isChangedLang} setChangedLang={setChangedLang}/>
      <RoutesM isEng={isEng} setEng={setEng} setScroller={setScrolled} />
      <Footer isEng={isEng} setEng={setEng} links={links}/>
      <CookieConsent
        location="bottom"
        buttonText={isEng ? "Accept Cookies" : "Accetta i Cookies"}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          backgroundColor: "#5cb77a", color: "white", fontSize: "13px", borderRadius: 5,
          fontFamily: "Montserrat, sans-serif"
        }}
        expires={150}
      >
        {isEng ? "We use cookies to give you the best experience on our website." : "Utilizziamo i cookie per offrirti la migliore esperienza sul nostro sito web."}
        {isEng ? "You can find out more about which cookies we are using or how to disable them in" : " Puoi scoprire di più su quali cookie stiamo utilizzando o come disattivarli in"} <a href="/cookies-policy">Cookies Policy</a>
      </CookieConsent>
    </div>
  );
}

export default App;
