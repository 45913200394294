import React from "react";
import { Routes, Route, } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from "react-router-dom";


import Home from "./Routes/Home/Home";
import LandingBar from "./Routes/LandingBar/LandingBar";
import LandingExchange from "./Routes/LandingExchange/LandingExchange";

//stili
import "./styles/global.scss"
import B2B from "./Routes/B2B/B2B";
import Contatti from "./Routes/Contatti/Contatti";
import LavoraConNoi from "./Routes/LavoraConNoi/LavoraConNoi";
import Menu from "./Routes/Menu/Menu";
import Evento from "./Routes/Evento/Evento";
import Prenota from "./Routes/Prenota/Prenota";
import Privacy from "./Routes/Legal/Privacy";
import Glossario from "./Routes/Legal/Glossario";
import Cookies from "./Routes/Legal/Cookies";
import TermsAccount from "./Routes/Legal/TermsAccount";
import TermsService from "./Routes/Legal/TermsService";
import Commissioni from "./Routes/Legal/Commissioni";
import TOSCBPay from "./Routes/Legal/TOSCBPay";

function RoutesM(props) {
  let navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  const [scrolled, setScrolled] = React.useState(false);
  let isEng = props.isEng
  React.useEffect(() => {
    window.addEventListener("scroll", (e) => {

      if (!scrolled && window.pageYOffset > 10) {
        setScrolled(true);
      }
      if (window.pageYOffset === 0) {
        setScrolled(false);
      }
    });

    return () => { // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", (e) => {
        console.log(e)
      });
    };
  }, []);

  const closeMenu = () => {
    let el = document.getElementById("side-menu")
    el.classList.toggle("active")
  }
  const goToPage = (arg) => {
    navigate("../" + arg, { replace: true })
  }
  return (

    <Routes>
      <Route index path="/" element={<Home isEng={isEng} isTabletOrMobile={isTabletOrMobile} />} />
      <Route path="exchange" element={<LandingExchange isEng={isEng} isTabletOrMobile={isTabletOrMobile} />} />
      <Route path="bar" element={<LandingBar isEng={isEng} isTabletOrMobile={isTabletOrMobile} />} />
      <Route path="/bar/menu" element={<Menu isEng={isEng} />} />
      <Route path="/bar/evento" element={<Evento isEng={isEng} />} />
      <Route path="/bar/prenota" element={<Prenota isEng={isEng} />} />
      <Route path="/b2b" element={<B2B isEng={isEng} />} />
      <Route path="/lavora-con-noi" element={<LavoraConNoi isEng={isEng} />} />
      <Route path="/contatti" element={<Contatti isEng={isEng} />} />
      <Route path="/privacy-policy" element={<Privacy isEng={isEng} />} />
      <Route path="/glossario-privacy" element={<Glossario isEng={isEng} />} />
      <Route path="/cookies-policy" element={<Cookies isEng={isEng} />} />
      <Route path="/terms-account" element={<TermsAccount isEng={isEng} />} />
      <Route path="/terms-service" element={<TermsService isEng={isEng} />} />
      <Route path="/terms-service-coinbar-pay" element={<TOSCBPay isEng={isEng} />} />
      <Route path="/commissioni" element={<Commissioni isEng={isEng} />} />
    </Routes>

  );
}

export default RoutesM;
