import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import sl1 from '../../assets/bar/downgraded/_86A8027.jpeg'
import sl2 from '../../assets/bar/downgraded/_86A8031.jpeg'
import sl3 from '../../assets/bar/downgraded/_86A8032.jpeg'
import sl4 from '../../assets/bar/downgraded/_86A8034.jpeg'
import sl5 from '../../assets/bar/downgraded/_86A8037.jpeg'
import sl6 from '../../assets/bar/downgraded/_86A8039.jpeg'
import sl7 from '../../assets/bar/downgraded/_86A8042.jpeg'
import sl8 from '../../assets/bar/downgraded/_86A8039.jpeg'
import sl9 from '../../assets/bar/downgraded/_86A8045.jpeg'
import sl10 from '../../assets/bar/downgraded/_86A8057.jpeg'
import sl11 from '../../assets/bar/downgraded/_86A8094.jpeg'
import sl12 from '../../assets/bar/downgraded/_86A8209.jpeg'


function Carosello(props) {


    return (

        <Carousel>
            <div>
                <img src={sl1} alt="Foto dell'interno del locale di Coinbar in Via Barberini 34"/>
            </div>
            <div>
                <img src={sl2}  alt="Foto dell'interno del locale di Coinbar in Via Barberini 34"/>
            </div>
            <div>
                <img src={sl3} alt="Foto dell'interno del locale di Coinbar in Via Barberini 34" />
            </div>
            <div>
                <img src={sl4}  alt="Foto dell'interno del locale di Coinbar in Via Barberini 34"/>
            </div>
            <div>
                <img src={sl5} alt="Foto dell'interno del locale di Coinbar in Via Barberini 34" />
            </div>
            <div>
                <img src={sl6}  alt="Foto dell'interno del locale di Coinbar in Via Barberini 34"/>
            </div>
            <div>
                <img src={sl7}  alt="Foto dell'interno del locale di Coinbar in Via Barberini 34"/>
            </div>
            <div>
                <img src={sl8} alt="Foto dell'interno del locale di Coinbar in Via Barberini 34" />
            </div>
            <div>
                <img src={sl9} alt="Foto dell'interno del locale di Coinbar in Via Barberini 34" />
            </div>
            <div>
                <img src={sl10}  alt="Foto dell'interno del locale di Coinbar in Via Barberini 34"/>
            </div>
            <div>
                <img src={sl11}  alt="Foto dell'interno del locale di Coinbar in Via Barberini 34"/>
            </div>
            <div>
                <img src={sl12}  alt="Foto dell'interno del locale di Coinbar in Via Barberini 34"/>
            </div>
        </Carousel>

    );
}

export default Carosello;
