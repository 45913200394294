import React from 'react';
import "./lavoraConNoi.scss"
import job from "../../assets/banner-04.png"
import FormLavoraConNoi from '../../Components/Forms/FormLavoraConNoi';
import SectionPartners from '../../Sections/SectionPartners/SectionPartners';
import { Helmet } from 'react-helmet';
import ita from './ita.json'
import eng from './eng.json'
function LavoraConNoi(props) {
  let language = props.isEng ? eng : ita
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div id="LavoraConNoi" >
      {
        props.isEng ?
          <Helmet>
            <title>Coinbar | Work with us</title>
            <meta name="title" content="Coinbar | Work with us" />
            <meta name="description" content="Coinbar is always looking for enthusiastic people with a desire to learn. If you are one of them, do not hesitate to contact us and start working in the world of Blockchain" />
            <meta name="keywords" lang="eng" content="exchange, criptovalute, aziendale, lavoro, recruiting, trading, blockchain" />
            <meta name="robots" content="index, follow" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="language" content="English" />
          </Helmet>
          :
          <Helmet>
            <title>Coinbar | Lavora con noi</title>
            <meta name="title" content="Coinbar | Lavora con noi" />
            <meta name="description" content="Coinbar è sempre alla ricerca di persone entusiaste e con voglia di apprendere. Se sei una di loro non esitare a contattarci e inizia a lavorare nel mondo della Blockchain" />
            <meta name="keywords" lang="it" content="exchange, criptovalute, aziendale, lavoro, recruiting, trading, blockchain" />
            <meta name="robots" content="index, follow" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="language" content="Italian" />
          </Helmet>
      }

      <section id="Job" className="">
        <div className="flex-container justify-center double-base-padding-side background-green">
          <div className="flex-1 align-center max-width-50 ueue">
            <img id="job3d" className="z-index-0" src={job} alt="Illustrazione 3D per lavorare con Coinbar" />
            <h2 className="text-center hero-heading text-white">{language.h2}</h2>
            <p className="text-center text-white">{language.p}</p>
            <FormLavoraConNoi isEng={props.isEng} />
            <div className="margin-bottom-100" />
          </div>
        </div>
      </section>
      <SectionPartners isEng={props.isEng} />
    </div>
  );
}

export default LavoraConNoi;
