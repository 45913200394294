import React from 'react'
import "./contatti.scss"
import FormContattiGenerali from '../../Components/Forms/FormContattiGenerali';
import ita from './ita.json'
import eng from './eng.json'
function Contatti(props) {
  let language = props.isEng ? eng : ita
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div id="Contatti" >
      <section id="Job" className="">
        <div className="flex-container justify-center double-base-padding-side margin-top-100">
          <div className="flex-1 align-center max-width-50 ueue">
            <h2 className="text-center hero-heading ">{language.h2}</h2>
            <p className="text-center ">{language.subtitle}</p>
            <FormContattiGenerali isEng={props.isEng}/>
            <div className="margin-bottom-100" />
          </div>
        </div>

      </section>

    </div>
  );
}

export default Contatti;
