import React from 'react';
import "./slideshow.scss"
import slide1 from "../../assets/downgraded/secure.gif"
import slide2 from "../../assets/downgraded/credit-cards.gif"
import slide3 from "../../assets/downgraded/mobile.gif"
import slide4 from "../../assets/downgraded/hero-crypto-building.gif"
import ita from './ita.json'
import eng from './eng.json'

function SlideShow(props) {
  const [autoplay, setAuto] = React.useState(true)
  let language = props.isEng ? eng : ita
  const changeSlide = (nr) => {
    let slideToActivate = document.getElementById("feat-" + nr)
    let textToActivate = document.getElementById("t-feat-" + nr)
    let controlToactivate = document.getElementById("c-feat-" + nr)
    let everySlide = document.getElementsByClassName("slide")
    let everyText = document.getElementsByClassName("text-slide")
    let everyControl = document.getElementsByClassName("control")

    everySlide[0].classList.remove("active")
    everySlide[1].classList.remove("active")
    everySlide[2].classList.remove("active")
    everySlide[3].classList.remove("active")
    everySlide[0].classList.add("inactive")
    everySlide[1].classList.add("inactive")
    everySlide[2].classList.add("inactive")
    everySlide[3].classList.add("inactive")
    everyText[0].classList.remove("active")
    everyText[1].classList.remove("active")
    everyText[2].classList.remove("active")
    everyText[3].classList.remove("active")
    everyText[0].classList.add("inactive")
    everyText[1].classList.add("inactive")
    everyText[2].classList.add("inactive")
    everyText[3].classList.add("inactive")
    everyControl[0].classList.remove("active")
    everyControl[1].classList.remove("active")
    everyControl[2].classList.remove("active")
    everyControl[3].classList.remove("active")
    controlToactivate.classList.add("active")
    window.setTimeout(() => {
      slideToActivate.classList.add("active")
      textToActivate.classList.add("active")
    }, 300)
  }


  return (
    <div className="SlideShow">
      <div className="slides">
        <img id="feat-1" src={slide1} className="slide active" alt="Icona 3d" />
        <img id="feat-2" src={slide2} className="slide inactive" alt="Icona 3d" />
        <img id="feat-3" src={slide3} className="slide inactive" alt="Icona 3d" />
        <img id="feat-4" src={slide4} className="slide inactive" alt="Icona 3d" />
      </div>
      <div className="slides-text">
        <div id="t-feat-1" className="text-slide inactive active">
          <h3>{language.Slide1.h3}</h3>
          <p>{language.Slide1.p1}<br />
            {language.Slide1.p2}<br />
            {language.Slide1.p3}</p>
        </div>
        <div id="t-feat-2" className="text-slide inactive">
          <h3>{language.Slide2.h3}</h3>
          <p>{language.Slide2.p1}<br />
            {language.Slide2.p2}<br />
            {language.Slide2.p3}</p>
        </div>
        <div id="t-feat-3" className="text-slide inactive">
        <h3>{language.Slide3.h3}</h3>
          <p>{language.Slide3.p1}<br />
            {language.Slide3.p2}<br />
            {language.Slide3.p3}</p>
        </div>
        <div id="t-feat-4" className="text-slide inactive">
        <h3>{language.Slide4.h3}</h3>
          <p>{language.Slide4.p1}<br />
            {language.Slide4.p2}<br />
            {language.Slide4.p3}</p>
        </div>
      </div>
      <div className="controls">
        <div id="c-feat-1" className="control active" onClick={() => { setAuto(false); changeSlide(1) }}>
          <div />
        </div>
        <div id="c-feat-2" className="control" onClick={() => { setAuto(false); changeSlide(2) }}>
          <div />
        </div>
        <div id="c-feat-3" className="control" onClick={() => { setAuto(false); changeSlide(3) }}>
          <div />
        </div>
        <div id="c-feat-4" className="control" onClick={() => { setAuto(false); changeSlide(4) }}>
          <div />
        </div>
      </div>
    </div>
  );
}

export default SlideShow;
