import React from 'react';
import "./sectionjob.scss"
import Button from "../../Components/Button/Button";
import IllustrazioneLavoro from '../../Components/Illustrazioni/IllustrazioneLavoro';
import ita from './ita.json'
import eng from './eng.json'

function SectionJob(props) {
    let language = props.isEng ? eng : ita

    return (

        <section id="Job" className="">
            <div className="flex-container justify-center double-base-padding-side background-green">
                <div className="flex-1 align-center max-width-70 ueue">
                    <IllustrazioneLavoro />
                    <h2 className="text-center hero-heading text-white">{language.h2}</h2>
                    <p className="text-center text-white">{language.p[0]}<br />{language.p[1]}</p>
                    <div className="margin-top-50"><Button white={true} link="lavora-con-noi" interno={false}>{language.button}</Button></div>
                    <div className="margin-bottom-100" />
                </div>
            </div>
        </section>

    );
}

export default SectionJob;
