import "./hamburger.scss"
import logoWhite from "../../assets/coinbar-horiz-white.svg"


function Hamburger(props) {

  const openMenu=()=>{
    let el= document.getElementById("side-menu")
    el.classList.toggle("active")
    console.log(el)
  }
  return (

    <div id="hamburger-nav" className={props.active&&"active"} onClick={openMenu}>
      <div />
      <div />
      <div />
    </div>

  );
}

export default Hamburger;
