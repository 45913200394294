import React from 'react'
import "./menu.scss"
import menuIT from './menu.json'

function Menu(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div id="Menu" >
      <div className="hero-banner">
        <h1>Menu</h1>
      </div>
      <section id="consigli">
        <h2>Lo Chef consiglia</h2>
        <div className="divider" />
        <div className="portate">
          {menuIT.Consigli.map(el => (
            <div className="portata">
              <div className="nome">
                <span className="-main">{el.nome + " "} </span>
                <span className="-second">{el.plus}</span>
              </div>
              <div className="prezzo">
                <span>€{el.prezzo}</span>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section id="carne">
        <h2>Burger & Steak</h2>
        <div className="divider" />
        <div className="portate">
          {menuIT.Carne.map(el => (
            <div className="portata">
              <div>
                <div className="nome">
                  <span className="-main">{el.nome + " "} </span>
                  <span className="-second">{el.plus}</span>
                </div>
                <div className="contorno">
                  <span className="-third">{el.aggiunta}</span>
                </div>
              </div>
              <div className="prezzo">
                <span>€{el.prezzo}</span>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section id="bevande">
        <h2>Vini, bevande & Dessert</h2>
        <div className="divider" />
        <div className="portate">
          {menuIT.Bevande.map(el => (
            <div className="portata">
              <div className="nome">
                <span className="-main">{el.nome + " "} </span>
                <span className="-second">{el.plus}</span>
              </div>
              <div className="prezzo">
                <span>€{el.prezzo}</span>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section id="note">
        <h2> </h2>
        <div className="divider" />
        <div className="portate">
          <div className="portata">
            <div className="nome">
              <span className="-main">Servizio al tavolo </span>
            </div>
            <div className="prezzo">
              <span>€1</span>
            </div>
          </div>
          <div className="portata">
            <div className="nome">
              <span className="-main">N.B.</span>
              <span className="-second"> a seconda della disponibilità alcuni ingredienti potrebbero essere congelati o abbattuti</span>
            </div>
            <div className="prezzo">
              <span></span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Menu;
