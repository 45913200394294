import React from 'react';
import "./illustrazioni.scss"
import srcImg from "../../assets/downgraded/banner-04.png"
import srcImgWebP from "../../assets/banner-04.webp"
import Image from 'react-image-webp';

function IllustrazioneLavoro(props) {

    return (
        <Image
            id="job3d" className="z-index-0"
            src={srcImg}
            webp={srcImgWebP}
            alt="Illustrazione 3D"
        />
    );
}

export default IllustrazioneLavoro;
