import "./partners.scss"
import partner1 from "../../assets/partner/chili_horiz_black.png"
import partner2 from "../../assets/partner/tradingview-logo-vector_wbg.png"
import partner3 from "../../assets/partner/chili_horiz_black.png"
import partner4 from "../../assets/partner/coint_horiz_black.png"
import partner5 from "../../assets/partner/doppelganger_horiz_black.png"
import partner6 from "../../assets/partner/liguori_horiz_black.png"
import partner7 from "../../assets/partner/nuvolari_horiz_black.png"
import partner8 from "../../assets/partner/kebhouze.png"

function Partners(props) {
  return (
    <div>
    {/*<h4 className="partners-heading">Alcuni dei partner che hanno già scelto Coinbar</h4>*/}
      <div className="partners-wrapper">
        <a href="https://www.tradingview.com/" target="_blank" rel="noopener noreferrer">
  <img className="partner-slide" src={partner2} alt="Logo di TradingView" /></a>
        <img className="partner-slide" src={partner3} alt="Logo di Chili Tv" href="" />
        <img className="partner-slide" src={partner4} alt="Logo di Cointelegraph" href="" />
        <img className="partner-slide" src={partner5} alt="Logo di Doppelganger" href="" />
        <img className="partner-slide" src={partner6} alt="Logo di Fireblocks" href="" />
        <img className="partner-slide" src={partner7} alt="Logo di Refinitv" href="" />
        <img className="partner-slide" src={partner8} alt="Logo di Kebhouze" href="" />
      </div>
    </div>
  );

}

export default Partners;
