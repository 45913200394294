import "./hero.scss"
function Hero(props) {
  return (
    <section className="Hero">
      {props.children}
    </section>
  );
}

export default Hero;
