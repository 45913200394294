import "./footer.scss"
import logoWhite from "../../assets/coinbar-horiz-white.svg"
import ita from './ita.json'
import eng from './eng.json'
import { useNavigate, useLocation } from "react-router-dom";

function Footer(props) {
  let language = props.isEng?eng:ita
  let links = props.links
  let isEng = props.isEng
  let navigate = useNavigate();
  const goToPage = (arg) => {
    navigate("../" + arg, { replace: true })
    
  }

  return (
    <footer >
      <div className="divider" />
      <div className="footer-container ">
        <div className="col">
          <div className="logo">
            <a><img src={logoWhite} alt="Logo di Coinbar" /></a>
          </div>
          <div className="footer-link-legal">
            <a href={isEng?links.terminiServizioENG:links.terminiServizio}  >{language.Link2}</a>
            <a href={isEng?links.terminiServizioPayENG:links.terminiServizioPay}   >{language.Link6}</a>
            <a href={isEng?links.commissioniENG:links.commissioni} >{language.Link3}</a>
            <a href={isEng?links.privacyENG:links.privacy}  >{language.Link4}</a>
            <a href={isEng?links.cookiesENG:links.cookies} >{language.Link5}</a>
            <a  href={isEng?links.documentazioneENG:links.documentazione}>{props.isEng ? "Documentation" : "Documentazione"}</a>
          </div>
          <span className="text-white">{language.UpFooter}</span>
          <span><a href={links.oam}>{language.SubFooter.span1}</a>{language.SubFooter.span2}<strong>{language.SubFooter.span3}</strong></span>

          <span className="text-white">© 2019 - 2022 Coinbar S.p.A. All rights reserved .</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
