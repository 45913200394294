import React from 'react'
import "./privacy.scss"
import { Formik } from 'formik';

function TermsAccount(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div id="TermsAccount" >
      <div className="hero-banner">
      </div>
      <section className="content">
        <h1>Incarico per la gestione di chiavi crittografiche
          relative a valute virtuali</h1>
        <h3>PREMESSO CHE</h3>
        <ol>
          <li>
            L’art. 3, punto 18, della direttiva (UE) 2015/849, come modificato dalla direttiva (UE) 2018/843 del Parlamento Europeo e del Consiglio del 30 maggio 2018 definisce la “valuta virtuale” (di seguito “Valuta Virtuale” o, al plurale, “Valute Virtuali”) come “una rappresentazione di valore digitale che non è emessa o garantita da una banca centrale o da un ente pubblico, non è necessariamente legata a una valuta legalmente istituita, non possiede lo status giuridico di valuta o moneta, ma è accettata da persone fisiche e giuridiche come mezzo di scambio e può essere trasferita, memorizzata e scambiata elettronicamente”.
          </li>
          <li>
            La gestione di Valute Virtuali avviene attraverso l’impiego di chiavi crittografiche di cui l’Utente dovrebbe avere una conoscenza esclusiva e che devono restare segrete e non accessibili a
          </li>
          <li>
            Al fine di mantenere la segretezza delle chiavi crittografiche occorrenti per la gestione di Valute Virtuali sono stati sviluppati appositi software e/o hardware il cui impiego richiede ancora oggi specifiche conoscenze informatiche e l’adozione di adeguati sistemi di
          </li>
          <li>
            Tale circostanza ostacola la diffusione delle Valute Virtuali e costituisce in sé un pericolo per l’Utente medio il quale può quindi avere interesse a delegare l’attività di gestione delle chiavi crittografiche relative a Valute Virtuali a soggetto dotato di idonee conoscenze tecniche e di risorse sufficienti per provvedere all’aggiornamento dei sistemi di sicurezza secondo i nuovi standard che si rendano nel tempo accessibili e disponibili nel
          </li>


        </ol>
        <p>
          Con delibera del Consiglio di Amministrazione del 21 marzo 2019 la società Coinbar
          S.p.A. (C.F. 14939301009), avente sede legale in Roma, alla Via della Marcigliana 532, ha costituto un patrimonio destinato (di seguito “Patrimonio Destinato”) al fine di gestire i rapporti contrattuali con gli utenti (di seguito “Utenti” o, al singolare, “Utente”) per quel che riguarda, anzitutto, l’attività di gestione delle chiavi crittografiche relative a Valute Virtuali.
        </p>
        <ol>
          <li>
            L’Utente ha individuato nel Patrimonio Destinato il soggetto in possesso del know how e delle conoscenze tecniche occorrenti per una migliore gestione delle chiavi crittografiche relative a Valute Virtuali per conto dell’Utente.
          </li>
          <li>
            Le Valute Virtuali presentano rischi elevati come peraltro segnalato anche con Avvertenza di Banca d’Italia del 19 marzo 2018, secondo cui, in particolare, esse comportano
            rischi di perdite permanenti delle somme utilizzate a causa di malfunzionamenti, attacchi informatici, mancanza di tutele legali e contrattuali, di obblighi informativi e di presidi di trasparenza, oltre che di forme di tutela o garanzia delle somme impiegate.
          </li>
        </ol>

        <h3>TUTTO CIÒ PREMESSO E CONSIDERATO</h3>
        <p>
          con l’accettazione dei presenti termini e condizioni, l’Utente conferisce incarico al Patrimonio Destinato affinché gestisca, per conto dell’Utente, le chiavi crittografiche relative alle Valute Virtuali del medesimo Utente.
        </p>

        <ol>
          <li>
            <h3>INCARICO DELL’UTENTE, CONDIZIONI PER L’ESPLETAMENTO DEI SERVIZI E POLICY</h3>
            <ul>
              <li>
                L’incarico si intende conferito ai sensi degli artt. 1703 e 1705 c.c. nonché a titolo
              </li>
              <li>
                L’Utente prende atto e riconosce sin d’ora che le attività oggetto dell’incarico conferito al Patrimonio Destinato costituiscono prestazione, svolta a titolo professionale, anche ai sensi della direttiva (UE) 2015/849, come modificata dalla direttiva (UE) 2018/843 del Parlamento Europeo e del Consiglio del 30 maggio 2018, nonché della normativa italiana di recepimento, di servizi funzionali alla gestione e salvaguardia di chiavi crittografiche (di seguito congiuntamente anche i “Servizi”). I Servizi sono offerti dal Patrimonio Destinato ai propri Utenti attraverso la piattaforma “Coinbar” accessibile sul sito Internet coinbar.it (di seguito la “Piattaforma”).
              </li>
              <li>
                Per l’effetto l’espletamento dei Servizi oggetto dell’incarico conferito dall’Utente potrà essere subordinato:
                alla preventiva identificazione dell’Utente secondo le modalità previste dalla normativa di volta in volta vigente;
                al compimento di specifiche azioni da parte del medesimo Utente;
                al positivo esito di controlli o verifiche ad opera del Patrimonio Destinato e del personale adibito alla gestione della Piattaforma.
              </li>
              <li>
                Le attività di cui al precedente punto 1.3 saranno svolte secondo le condizioni ed i termini e con le modalità indicate nella sezione “Policy AML” e nella sezione “Privacy Policy” della Piattaforma le cui previsioni devono qui intendersi integralmente richiamate e trascritte, formando parte integrante e sostanziale dei o.S.
              </li>
              <li>
                Con il conferimento dell’incarico l’Utente accetta di obbligarsi alla scrupolosa osservanza della normativa di volta in volta vigente, inclusa quella concernente la prevenzione dell’utilizzo del sistema finanziario a scopo di riciclaggio dei proventi di attività
                criminose e di finanziamento del terrorismo. L’Utente si impegna in particolare, e tra le altre cose, a fornire al Patrimonio Destinato i propri dati identificativi e documenti di riconoscimento validi, nonché le ulteriori informazioni richieste da quest’ultimo, per l’adempimento degli obblighi di previsti dalle norme di volta in volta vigenti.
              </li>
              <li>
                Per l’effetto l’Utente assume l’obbligo di farsi carico di ogni rischio, pregiudizio, danno, costo od onere, nessuno escluso, che il Patrimonio Destinato e, per quanto occorra, Coinbar
                S.p.A. dovessero subire a causa della violazione da parte dell’Utente medesimo del punto  1.5.
              </li>
              <li>
                In ogni caso, la Piattaforma potrà in ogni momento rifiutare l’incarico o rinunciare allo stesso, senza che l’Utente possa avanzare qualsivoglia pretesa, qualora sorga il sospetto di violazioni, da parte dell’Utente, della normativa vigente, inclusa quella concernente la prevenzione dell’utilizzo del sistema finanziario a scopo di riciclaggio dei proventi di attività criminose e di finanziamento del terrorismo.
              </li>
            </ul>
          </li>
          <li>
            <h3>
              VALORE DEI TERMS OF SERVICES/T.O.S..
            </h3>
            <ul>
              <li>
                L’accesso alla Piattaforma è riservato agli Utenti registrati ed i Servizi sono svolti in base alle presenti clausole (di seguito anche “Terms of Services” or “o.s.”).
              </li>
              <li>
                L’Utente è invitato a leggere con la massima attenzione i o.S.
              </li>
              <li>
                I Servizi sono servizi prestati a distanza mediante la rete Internet. Cliccando sul pulsante “Accept” e/o usufruendo dei Servizi gli Utenti accettano di obbligarsi al rispetto integrale dei o.S.
              </li>
              <li>
                È responsabilità dell’Utente verificare che i Servizi soddisfino le sue aspettative ed esigenze prima di cliccare sul pulsante “Accept” o di accedere ai Servizi
              </li>
            </ul>
          </li>
          <li>
            <h3>
              DEFINIZIONI.
            </h3>
            <ul>
              <li>
                Nelle clausole dei o.S., i seguenti termini avranno il significato ai medesimi di seguito convenzionalmente attribuito:<br />

                <ul>
                  <li>Account                                  si intende il conto virtuale dedicato dell’Utente che diviene operativo a seguito della relativa registrazione</li>

                  <li>Coinbar S.p.A.                      si intende la società Coinbar S.p.A., con sede legale in Roma,

                    Via della Marcigliana n. 532 – Codice fiscale e Partita IVA n. 14939301009, REA: RM – 1556675</li>

                  <li> Dati di Login                         si intendono  entrambi (i)  la  username e (ii)  la  password scelti

                    dagli   Utenti   in   sede   di   registrazione   per   l’accesso   alla Piattaforma;</li>

                  <li>    Patrimonio Destinato       ha il significato indicato in premessa;</li>

                  <li>    Servizi                                     ha il significato indicato in premessa;</li>

                  <li>    Terms of Services              ha il significato indicato in premessa;</li>

                  <li>     T.o.S.                                      ha il significato indicato in premessa;</li>

                  <li>       Utenti                                     ha il significato indicato in premessa.</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <h3>

              ACCESSO AI SERVIZI E REGISTRAZIONE.
            </h3>
            <ul>
              <li>
                Per accedere ai Servizi l’Utente deve preventivamente registrarsi sulla Piattaforma. La registrazione richiede che l’Utente fornisca:
                tutti i dati indicati sulla Piattaforma come dati obbligatori e
                eventualmente gli ulteriori dati indicati sulla Piattaforma come
                Sulla Piattaforma è riportata l’indicazione dei dati obbligatori e di quelli facoltativi da fornire ai fini della registrazione.
              </li>
              <li>
                L’Utente dichiara di essere consapevole che alcuni Servizi potrebbero essere riservati, anche per ragioni tecniche, solo agli Utenti che abbiano provveduto a fornire taluni dati facoltativi secondo quanto indicato sulla Piattaforma.
              </li>
              <li>
                I dati inseriti in fase di registrazione comprendono il nome dell’Utente (“Username”), che corrisponde all’indirizzo e-mail fornito, e la chiave di accesso (“Password”) che, considerati unitariamente, costituiscono i Dati di Login.
              </li>
              <li>
                Al completamento della registrazione l’Utente avrà accesso ad un account dedicato attraverso il quale l’Utente potrà impartire istruzioni alla Piattaforma nel rispetto dei vincoli ivi indicati. L’operatività dell’account potrà essere subordinata, tra le altre cose, alla preventiva conferma della registrazione attraverso il link che l’Utente riceverà all’indirizzo e- mail da lui
              </li>
              <li>
                La registrazione sulla Piattaforma e l’accesso all’account sono
                L’account dell’Utente ha durata indeterminata, fermo il diritto di ciascuna parte di recedere unilateralmente in qualunque momento dal rapporto contrattuale, con conseguente cancellazione dell’account, previa semplice comunicazione da trasmettersi all’altra parte per posta elettronica.
              </li>
            </ul>
          </li>
          <li>
            <h3> ATTIVITÀ NON CONSENTITE ALL’UTENTE.</h3>
            <p>
              Gli Utenti hanno il divieto di compiere le attività di seguito elencate senza il preventivo
              consenso scritto del Patrimonio Destinato:
            </p>
            <ul>
              <li>
                condividere i Dati di Login con qualunque terza parte o comunque consentire ad una qualunque terza parte di utilizzare i Dati di Login, indipendentemente dal fatto che tali attività siano svolte o meno a fronte di un corrispettivo;
              </li>
              <li>
                assegnare o trasferire il proprio account o qualunque diritto di cui gli Utenti possano essere titolari in base ai o.S. a qualunque terza parte, indipendentemente dal fatto che tali attività siano svolte o meno a fronte di un corrispettivo;
              </li>
              <li>
                usufruire dei Servizi al fine di prestare o offrire un qualunque servizio a qualunque terza parte o comunque usufruire dei Servizi per scopi diversi da quelli strettamente personali;
              </li>
              <li>
                pubblicare, diffondere o fornire copia a qualunque terza parte delle informazioni rese disponibili sulla Piattaforma;
              </li>
              <li>
                cancellare, rimuovere o alterare qualunque marchio, logo, immagine o dato di titolarità del Patrimonio Destinato.
              </li>
              <li>
                Per l’effetto l’Utente assume l’obbligo di farsi carico di ogni rischio, pregiudizio, danno, costo od onere, nessuno escluso, che il Patrimonio Destinato e, per quanto occorra, Coinbar
                S.p.A. dovessero subire a causa della violazione da parte dell’Utente medesimo del punto  5.1.
              </li>
            </ul>
          </li>

          <li>
            <h3>I SERVIZI.</h3>
            <p>
              I Servizi prestati tramite la Piattaforma ed oggetto dell’incarico conferito dall’Utente consistono:
            </p>
            <ul>
              <li>
                nella salvaguardia e gestione delle chiavi crittografiche relative a  Valute Virtuali
                per conto dell’Utente;</li>
              <li>
                nell’esecuzione degli ordini impartiti dall’Utente per il trasferimento delle Valute Virtuali associate al proprio account su indirizzi per i quali l’Utente stesso abbia la conoscenza esclusiva delle connesse chiavi
              </li>
            </ul>
            <p>
              L’Utente prende atto, accetta e riconosce sin d’ora che dai Servizi è in ogni caso esclusa l’attività volta a consentire il trasferimento di Valute Virtuali per conto dell’Utente su indirizzi diversi da quelli per i quali l’Utente medesimo abbia la conoscenza esclusiva delle connesse chiavi crittografiche. L’Utente garantisce sin d’ora, pertanto, di avere la conoscenza esclusiva delle chiavi crittografiche connesse agli indirizzi indicati per il trasferimento di Valute Virtuali.
              Per l’effetto l’Utente assume l’obbligo di farsi carico di ogni rischio, pregiudizio, danno,
              costo od onere, nessuno escluso, che il Patrimonio Destinato e, per quanto occorra, Coinbar S.p.A. dovessero subire a causa della violazione da parte dell’Utente medesimo del punto  6.2.
            </p>
            <p>
              L’Utente, inoltre, prende atto, accetta e riconosce che, a meri fini di sicurezza, l’esecuzione degli ordini impartiti dal medesimo ai sensi del precedente punto 6.1.2 potrà essere anche obbligatoriamente subordinata al preventivo compimento, da parte dell’Utente, di apposite azioni volte a confermare la volontà dello stesso di compiere le attività di cui trattasi, anche tramite strumenti di convalida o doppia autenticazione (così detti “2FA” o “two factors authentication”).
              Resta in ogni caso inteso che l’Utente ha la esclusiva responsabilità di gestire ogni dato, meccanismo o strumento di convalida o doppia autenticazione o, comunque, volto a consentire la relativa identificazione a distanza in modo da impedire ogni forma di accesso abusivo all’account al medesimo dedicato. In ogni caso l’Utente dovrà informare immediatamente il Patrimonio Destinato per qualunque accesso abusivo al proprio account di cui sia venuto a conoscenza o che sospetti, così come della perdita o della diffusione involontaria a terzi dei Dati di Login o, ancora, del fatto che, per qualsiasi altra ragione, terze parti possano aver avuto o avere accesso agli stessi o, ancora, ad ogni altro dato, meccanismo o strumento di convalida o doppia autenticazione o, comunque, volto a consentire l’identificazione dell’Utente a distanza per impedire ogni forma di accesso abusivo all’account al medesimo
              Per l’effetto l’Utente assume l’obbligo di farsi carico di ogni rischio, pregiudizio, danno, costo od onere, nessuno escluso, che il Patrimonio Destinato e, per quanto occorra, Coinbar
              S.p.A. dovessero subire a causa di una non adeguata custodia e conservazione, da parte dell’Utente medesimo, dei Dati di Login e/o a causa di un utilizzo improprio o negligente di ogni dato, meccanismo o strumento di convalida o doppia autenticazione o, comunque, volto a consentire la relativa identificazione a distanza per impedire ogni forma di accesso abusivo all’account al medesimo dedicato.
            </p>
            <p>
              L’Utente prende atto, accetta e riconosce che i tempi occorrenti per il completamento delle procedure indicate nella Piattaforma potranno variare in ragione del tipo di Valuta Virtuale e del connesso valore espresso in moneta a corso forzoso secondo le quotazioni di In ogni caso, l’Utente non potrà vantare pretese di sorta verso il Patrimonio Destinato e, per quanto occorra, Coinbar S.p.A. per ogni ritardo dipendente dal mancato rispetto delle procedure indicate nella Piattaforma.
            </p>
            <ul>
              <li>
                Per le attività di cui al punto 1.1:<br />
                la salvaguardia e gestione delle chiavi crittografiche relative a Valute Virtuali per conto dell’Utente potrà essere limitata entro determinate soglie in funzione del tipo di Valuta Virtuale, del connesso valore espresso in moneta a corso forzoso secondo le quotazioni di mercato e del livello di verifica dell’Utente secondo le procedure indicate nella Piattaforma;
                il Patrimonio Destinato potrà immediatamente recedere dal rapporto con l’Utente, con conseguente eliminazione del suo account, nel caso in cui l’indirizzo di provenienza della Valuta Virtuale di cui il Patrimonio Destinato sia chiamato a gestire le connesse chiavi crittografiche risulti, direttamente od indirettamente, associato ad indirizzi impiegati per il compimento di attività illecite o sospette, salvo il diritto dell’Utente al trasferimento delle Valute Virtuali su indirizzi per i quali l’Utente stesso abbia la conoscenza esclusiva nei limiti consentiti dalla normativa di volta in volta vigente e fermo tuttavia il diritto del Patrimonio Destinato di segnalare la circostanza alle autorità competenti;
                in un’apposita sezione dell’account l’Utente potrà accedere a tutti i dati riguardanti lo storico delle Valute Virtuali di cui il Patrimonio Destinato sia chiamato a gestire le connesse chiavi crittografiche per conto dell’Utente
              </li>
              <li>
                Per le attività di cui al punto 1.2:<br />
                il trasferimento delle Valute Virtuali su indirizzi per i quali l’Utente stesso abbia la conoscenza esclusiva delle connesse chiavi crittografiche potrà essere limitato entro determinate soglie a seconda del tipo di Valuta Virtuale, del connesso valore espresso in moneta a corso forzoso secondo le quotazioni di mercato e del livello di verifica dell’Utente secondo le procedure indicate nella piattaforma Nuex;
                l’Utente è responsabile di fornire al Patrimonio Destinato un indirizzo per il quale l’Utente stesso abbia la conoscenza esclusiva delle connesse chiavi crittografiche e dovrà assicurarsi, sotto la propria esclusiva responsabilità, che l’indirizzo fornito per il ritiro sia corretto;
                l’Utente, sotto la propria esclusiva responsabilità, al fine di tutelare la riservatezza dei propri dati, dovrà inoltre evitare di fornire il medesimo indirizzo per più occasioni e sarà direttamente responsabile di ogni rischio, pregiudizio, danno, costo od onere, nessuno escluso, derivante dal mancato rispetto di tale previsione;
                deve quindi intendersi vietata l’indicazione di indirizzo per il quale l’Utente non abbia la conoscenza esclusiva delle connesse chiavi crittografiche o che l’Utente abbia già fornito in precedenza;
                in ogni caso, l’Utente non potrà vantare pretese di sorta verso il Patrimonio Destinato e, per quanto occorra, Coinbar S.p.A. nel caso in cui abbia fornito il medesimo indirizzo già fornito in precedenza ovvero un indirizzo errato o, comunque, per il quale l’Utente non abbia la conoscenza esclusiva delle connesse chiavi crittografiche e assume l’obbligo di farsi carico di ogni rischio, pregiudizio, danno, costo od onere, nessuno escluso, che il Patrimonio Destinato e, per quanto occorra, Coinbar
                S.p.A. dovessero subire a causa della violazione da parte dell’Utente medesimo delle procedure sopra indicate;
              </li>
            </ul>
            <p>
            in un’apposita sezione dell’account l’Utente potrà accedere a tutti i dati riguardanti lo storico delle Valute Virtuali per le quali l’Utente abbia chiesto ed ottenuto il trasferimento ad indirizzi per i quali l’Utente stesso abbia la conoscenza esclusiva delle connesse chiavi
        </p>
          </li>
          <li>
            <h3>DICHIARAZIONI.</h3>
            Gli Utenti prendono atto che tutte le informazioni fornite dal Patrimonio Destinato o, per esso, da Coinbar S.p.A. o, ancora, sulla Piattaforma non potranno essere in nessun caso considerate come invito o sollecitazione ad acquistare o vendere Valute Virtuali.
            Nel registrarsi sulla Piattaforma l’Utente dichiara che la detenzione di Valuta Virtuale in relazione alla quale l’Utente medesimo abbia conferito incarico al Patrimonio Destinato per la gestione delle connesse chiavi crittografiche è allo stesso legalmente consentito in base alle norme applicabili al medesimo anche in ragione della propria nazionalità e/o della propria residenza e/o domicilio e/o di qualsivoglia altro criterio di collegamento. L’Utente assume l’obbligo di farsi carico di ogni rischio, pregiudizio, danno, costo od onere, nessuno escluso, che il Patrimonio Destinato e, per quanto occorra, Coinbar S.p.A. dovessero subire a causa di una dichiarazione non corretta o non veritiera dell’Utente.
            Con l’accettazione dei presenti o.S. l’Utente dichiara inoltre:
            di aver visionato e esaminato con attenzione i presenti o.S., la sezione “Policy AML” e la sezione “Privacy Policy” della Piattaforma e di averne pertanto preso completa conoscenza;
            di avere conoscenza ed esperienza nel settore delle Valute Virtuali e dei rischi ad esse connessi;
            7.3.3. di essere consapevole che l’incarico conferito dall’Utente non comporta alcuna garanzia di alcun tipo e, in particolare, non determina alcun impegno, obbligo o garanzia, a carico del Patrimonio Destinato e, per quanto occorra, di Coinbar S.p.A., a

            mantenere invariato o stabile o superiore ad una qualunque soglia il valore, espresso in moneta a corso legale forzoso, delle Valute Virtuali oggetto dell’incarico conferito dall’Utente;

            di essere consapevole che le Valute Virtuali, tenuto conto della Sentenza della Corte di Giustizia UE nella causa C‑264/14 in relazione al caso specifico del bitcoin, che ne costituisce primo esempio storico e per diffusione, possono al più qualificarsi, nell’ambito del diritto applicabile ai paesi membri dell’Unione Europea, quale mero mezzo o strumento di pagamento, a base contrattuale o volontaria; esse, pertanto, non costituiscono strumenti o prodotti finanziari nel senso indicato dalla normativa di riferimento, inclusi (i) la Direttiva 2014/65/UE del Parlamento Europeo e del Consiglio del 15 maggio 2014 ss.mm.ii. e (ii) il Lgs. n. 58 del 1998 ss.mm.ii. (“Testo Unico Finanziario” o “T.U.F.”) che, all’art. 1, comma 2, prevede espressamente che “[g]li strumenti di pagamento non sono strumenti finanziari”: le Valute Virtuali, d’altra parte, non assicurano dividendi, cedole, interessi o qualsivoglia altra forma di rendimento che non sia strettamente ed esclusivamente legata all’eventuale ed ipotetico aumento del relativo valore espresso in moneta corso legale forzoso, né fanno sorgere alcun diritto alla restituzione di una somma;
            di essere consapevole che il valore delle Valute Virtuali espresso in moneta a corso legale forzoso dipende esclusivamente dall’andamento del mercato e che non vi sono autorità che possano certificare, in maniera assoluta o “ufficiale”, tale valore;
            di essere consapevole dei rischi di non facile liquidazione delle Valute Virtuali che possono incidere anche in misura rilevante sul valore delle Valute Virtuali espresso in moneta corso legale forzoso;
            di essere consapevole che il trasferimento di Valute Virtuali comporta, per la natura stessa della connessa tecnologia, la necessità di versare delle commissioni (“fees”) in Valuta Virtuale, la cui entità e misura non può essere predeterminata con esattezza, di cui dovrà farsi carico l’Utente;
            di essere consapevole che il valore delle Valute Virtuali espresso in moneta corso legale forzoso può risentire anche di eventuali problematiche connesse allo sviluppo del codice o software di riferimento, nonché alla governance ed alla trasparenza dei progetti di sviluppo, e del sistema o rete connessi;
            di essere consapevole che il Patrimonio Destinato e, per quanto occorra, Coinbar
            S.p.A. non hanno alcuna possibilità o capacità, né assumono impegni o obblighi di sorta ad intervenire sui fattori e sui processi di cui al presente punto 7.3 al fine di tutelare

            qualsivoglia interesse dell’Utente.

            L’Utente dichiara, infine, di essere consapevole che gli effetti della delibera del Consiglio di Amministrazione del 21 marzo 2019 di Coinbar S.p.A. con cui è stato costituito il Patrimonio Destinato sono stati sospensivamente condizionati al verificarsi di determinati presupposti entro il termine indicato nella medesima delibera. Con effetti decorrenti dalla data in cui la condizione sospensiva si sarà verificata, il rapporto contrattuale derivante dall’accettazione dei presenti o.S. si intenderà instaurato direttamente tra Utente e Patrimonio Destinato. Nelle more tale rapporto contrattuale farà invece capo a Coinbar S.p.A., intendendosi ogni riferimento al Patrimonio Destinato automaticamente sostituito con la Coinbar S.p.A.
          </li>
          <li>
            <h3>MODALITÀ DI ESPLETAMENTO DEI SERVIZI – STANDARD DI RIFERIMENTO.</h3>
            Il Patrimonio Destinato si obbliga a svolgere l’incarico conferito dall’Utente adottando strumenti, procedure, software ed hardware di livello almeno corrispondente a quelli adottati per prassi nella media del mercato per analoghe attività.
            L’Utente riconosce ed accetta che non esistono strumenti, procedure, software ed hardware, in base alle conoscenze scientifiche attuali ed allo sviluppo tecnologico odierno, che assicurino l’eliminazione completa di ogni rischio di sicurezza nella gestione di chiavi crittografiche connesse a Valute Virtuali. Per l’effetto, nessuna responsabilità potrà essere imputata al Patrimonio Destinato e, per quanto occorra, alla Coinbar S.p.A. in caso di accessi abusivi, frodi informatiche o altre attività illecite di terzi che non avrebbero potuto essere evitati con l’impiego di strumenti, procedure, software ed hardware di livello almeno corrispondente a quelli adottati per prassi nella media del mercato per analoghe attività.
          </li>
          <li>
            <h3>RAPPORTI ECONOMICI ESPRESSI IN MONETA A CORSO FORZOSO.</h3>
            L’Utente riconosce che, in funzione dell’eventuale costituzione di un rapporto contrattuale tra lo stesso ed il Patrimonio Destinato per lo svolgimento di attività di acquisto o vendita di Valute Virtuali con altre Valute Virtuali o con monete aventi corso legale forzoso, il Patrimonio Destinato potrebbe di volta in volta assumere un debito verso lo stesso Utente per effetto della intervenuta cessione di Valute Virtuali per suo conto e su sua indicazione ovvero per il versamento di somme, ad opera del medesimo, a favore del Patrimonio Destinato finalizzato al successivo acquisto di Valute Virtuali.
            Gli eventuali debiti in moneta avente corso legale forzoso saranno pagati dal Patrimonio Destinato a semplice richiesta dell’Utente, da effettuarsi nel rispetto delle procedure indicate
            sulla Piattaforma, entro 48 ore dalla richiesta. I connessi versamenti saranno effettuati esclusivamente in euro con trasferimento sul conto corrente indicato dall’Utente. È espressamente e rigorosamente esclusa ogni possibilità di versamento in favore di soggetti diversi dall’Utente.

            Al fine di consentire al Patrimonio Destinato di poter effettuare i versamenti sopra indicati l’Utente ha l’onere di indicare con precisione e correttezza gli estremi identificativi del proprio conto corrente e di comprovare la relativa titolarità.
          </li>
          <li>
            <h3>MODIFICHE DEI O.S.</h3>
            Il Patrimonio Destinato si riserva il diritto di modificare i o.S.
            Le modifiche ai o.S. diventeranno immediatamente efficaci per i nuovi Utenti.
            Gli Utenti già registrati saranno informati di ogni modifica ai o.S. ed avranno il diritto di recedere entro i 14 (quattordici) giorni successivi al ricevimento di tale informativa. Gli Utenti intenzionati a recedere dovranno esercitare il diritto di recesso compilando ed inviando il modello di comunicazione che può essere scaricato al seguente link                                            o qualunque altra comunicazione con contenuto equivalente. Le comunicazioni di recesso dovranno essere inviate per posta elettronica al seguente indirizzo e-mail: support@coinbar.io.
            Gli Utenti che, a seguito del ricevimento dell’informativa sulle modifica dei o.S., non esercitassero il recesso entro il termine sopra indicato o, comunque, usufruissero dei Servizi si considerano come se avessero accettato tali modifiche espressamente.
          </li>
          <li>
            <h3>GARANZIE E RESPONSABILITÀ.</h3>
            Qualunque link riportato nella Piattaforma è riportato per mera utilità e né il Patrimonio Destinato, né Coinbar S.p.A. potranno essere ritenuti in alcun modo responsabili per il contenuto, l’accessibilità e/o l’operatività del sito cui faccia riferimento il link
            Il Patrimonio Destinato impiegherà ogni ragionevole sforzo per assicurare l’accessibilità ai Servizi su base
            In ogni caso, tenuto conto che i Servizi sono prestati a distanza mediante la rete Internet, l’Utente accetta il rischio di un temporaneo malfunzionamento che possa impedire o limitare la possibilità per il Patrimonio Destinato di prestare i Servizi su base continuativa. Né il Patrimonio Destinato, né Coinbar S.p.A. potranno essere ritenuti responsabili per eventuali malfunzionamenti a meno che non sia comprovato che essi siano dipesi da grave
          </li>
          <li>
            <h3>RECESSO/REVOCA INCARICO.</h3>
            Ciascuna parte avrà il diritto di recedere unilateralmente in ogni momento dal rapporto contrattuale. L’Utente intenzionato a recedere revocando l’incarico conferito potrà farlo compilando ed inviando  il  modello  di comunicazione che può  essere scaricato  al seguente
            link                                          o  qualunque altra  comunicazione con contenuto equivalente.

            Le comunicazioni di recesso/revoca incarico dovranno essere inviate per posta elettronica al seguente indirizzo e-mail: support@coinbar.io.

            L’Utente prende atto e riconosce che il proprio diritto di recesso unilaterale ai sensi del precedente punto non è soggetto a termini, né limitazioni di sorta e, pertanto, potendo essere esercitato a condizioni maggiormente favorevoli, supera quello eventualmente spettante al medesimo anche ai sensi della Direttiva 2011/83/UE del Parlamento Europeo e del Consiglio del 25 ottobre 2011, della Direttiva 93/13/CEE del Consiglio e della Direttiva 1999/44/CE del Parlamento Europeo e del Consiglio, nonché delle normativa italiana di recepimento (incluso il Decreto legislativo, n° 206 del 2005 mm.ii.).
          </li>
          <li>
            <h3>SUPPORTO ALL’UTENTE ED ASSISTENZA.</h3>
            Gli Utenti dovranno presentare ogni contestazione in merito ai Servizi al seguente indirizzo e-mail: support@coinbar.io.
          </li>
          <li>
            <h3>TUTELA DELLA RISERVATEZZA DEI DATI DELL’UTENTE</h3>
            I dati personali e più in generale ogni informazione, riferibile all’Utente, raccolta dal Patrimonio Destinato in ragione dei presenti o.S. sarà conservata e trattata nel rispetto della normativa vigente, con particolare riferimento alle disposizioni contenute nel Regolamento (UE) 2016/679 c.d. GDPR ed al D.Lgs. 196/2003, così come modificato dal D.Lgs. 108/2018, secondo quanto indicato nella sezione “Privacy Policy” della Piattaforma le cui previsioni che devono qui intendersi integralmente richiamate e trascritte, formando parte integrante e sostanziale dei T.o.S.
          </li>
          <li>
            <h3>LEGGE APPLICABILE E FORO ESCLUSIVO.</h3>
            I o.S. saranno interpretati ed applicati secondo la legge della Repubblica Italiana e soggetti alla stessa.
            Per qualunque controversia relativa all’interpretazione, efficacia, validità, esecuzione o risoluzione dei o.S. la competenza, fatti salvi i limiti inderogabili di legge, è attribuita in via esclusiva al Foro di Roma, con esclusione di ogni foro concorrente.
          </li>
          <li>
            <h3>INVALIDITÀ/INEFFICACIA.</h3>
            L’invalidità o inefficacia di qualunque clausola dei presenti o.S. non determina l’invalidità o inefficacia integrale dei medesimi T.o.S.
            La clausola eventualmente ritenuta invalida o inefficace sarà automaticamente sostituita con altra clausola che risulti conforme alle previsioni di legge e che consenta di addivenire al medesimo risultato pratico, specie per quel che attiene alla suddivisione di responsabilità tra l’Utente e il Patrimonio Destinato (o, per esso, Coinbar p.A.).
          </li>
          <li>
            <h3>
              CLAUSOLE VESSATORIE
            </h3>
            L’Utente dichiara di aver letto e compreso e di approvare specificamente le clausole di cui ai precedenti punti: 1.6 e 1.7 (in relazione alle responsabiltà dell’Utente ed alla rinuncia all’incarico del Patrimonio Destinato ivi previste), 4.6 (in relazione alla facoltà di recesso unilaterale del Patrimonio Destinato ivi prevista), 5.2 (in relazione alle responsabiltà dell’Utente ivi previste), 6.3, 6.6, 6.8.2 e 6.9.5 (in relazione alle responsabiltà dell’Utente, alle limitazioni di responsabilità del Patrimonio Destinato e di Coinbar S.p.A. e alle ipotesi di recesso unilaterale del Patrimonio Destinato ivi previste), 7.2 (in relazione alle responsabiltà dell’Utente ivi previste), 8.2 (in relazione alle limitazioni di responsabilità del Patrimonio Destinato e di Coinbar S.p.A. ivi previste), 10 (in relazione alle ipotesi di recesso unilaterale del Patrimonio Destinato ed alle decadenze ivi previste), 11 (in relazione alle limitazioni di responsabilità del Patrimonio Destinato e di Coinbar S.p.A. ivi previste), 12 (in relazione alle ipotesi di recesso unilaterale del Patrimonio Destinato ivi previste) e 15 (in relazione alla legge applicabile ed alla scelta del foro esclusivo per le controversie).
          </li>
        </ol>
      </section>
    </div >
  );
}

export default TermsAccount;
